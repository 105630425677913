import React, { useRef, useState } from 'react'
//import HeaderIsAuth from '../components/HeaderIsAuth'
import Header from '../components/Header'

const Map = () => {
  const iframeRef = useRef(null)
  const [loading, setLoading] = useState(true)

  const handleLoad = () => {
    setLoading(false)
  }

  return (
    <>
      <title>ZeroShit ੶ Карта сервера</title>
      <Header/>
      <div className='map map_no_auth'>
        {loading && <div className='load'><h2>Загрузка</h2><div class="loader"></div></div>}
        <iframe ref={iframeRef} src="http://5.9.97.124:20370/" title='Карта' onLoad={handleLoad}/> 
      </div>
    </>
  )
}

export default Map