import React, { useContext, useEffect, useState } from 'react'
import Header from '../components/Header'
import { Context } from '..'
import { authentication, search_promocode, get_payment_url } from '../http/userAPI'
import { BiLinkExternal } from 'react-icons/bi'

const Guest = () => {
    const {user} = useContext(Context)
    const [loading, setLoading] = useState(true)
    const [access, setAccess] = useState({promocode: "", nick: ""})
    const [price, setPrice] = useState(120)


    useEffect(() => {
        authentication().then(data => {
            if (data !== null) {
              if (data.hasOwnProperty("data")) {
                user.setUser(data.data)
                user.setIsAuth(data.data.authorization)
              }
            }
          }).finally(() => setLoading(false))
    // eslint-disable-next-line
    }, [])

    const check_promocode = async () => {
        try {
            setPrice()
            if (access.promocode !== "") {
                search_promocode(access.promocode).then(data => {
                    if (data === null) {
                        setPrice(120)
                    } else if (data.error) {
                        alert(data.error)
                        setPrice(120)
                    } else {
                        setPrice(120 - 120 * data.discount / 100)
                    }
                })
            } else {
                setPrice(120)
            }
        } catch (err) {
            console.log(err)
            alert(err.response.data.message)
        }
    }

    const buy = async () => {
        try {
            if (access.nick === "") {
                return alert("Введите ник")
            }
            setPrice()
            if (access.promocode !== "") {
                search_promocode(access.promocode).then(data => {
                    if (data === null) {
                        setPrice(120)
                    } else if (data.error) {
                        alert(data.error)
                        setPrice(120)
                    } else {
                        setPrice(120 - 120 * data.discount / 100)
                    }
                })
            }
            console.log(price, access.nick, access.promocode)
            get_payment_url(price, access.nick, access.promocode).then(data => {
                if (data === null) {
                    setPrice(120)
                } else if (data.error) {
                    alert(data.error)
                    setPrice(120)
                } else {
                    window.location.href = data;
                }
            })
            
        } catch (err) {
            console.log(err)
            alert(err.response.data.message)
        }
    }

    if (loading) {
        return (
            <div>
                <Header/>
                <div className='load'>
                    <div className="dot-spinner">
                    <div className="dot-spinner__dot"></div>
                    <div className="dot-spinner__dot"></div>
                    <div className="dot-spinner__dot"></div>
                    <div className="dot-spinner__dot"></div>
                    <div className="dot-spinner__dot"></div>
                    <div className="dot-spinner__dot"></div>
                    <div className="dot-spinner__dot"></div>
                    <div className="dot-spinner__dot"></div>
                    </div>
                </div>
            </div>
          
        )
      }
    
    const onFormSubmit = e => {
        e.preventDefault()
        check_promocode()
    }
    
    return (
        <div>
            <title>ZeroShit ੶ Покупка проходки</title>
            <Header/>
            <div className='access'>
                <div className='access_modal'>
                    <div className='access_modal-types'>
                        <div>
                            <h1>Вечная проходка</h1>
                            <h3>Постоянный доступ к серверу</h3>
                        </div>
                    </div>
                    <form className='access_modal-form' onSubmit={onFormSubmit}>
                        <p>Введите промокод <span className='optional'>(необязательно)</span></p>
                        <div className='access_modal-form_container'>
                            <div className='input-container'>
                                <input id="promocode" value={access.promocode} type='text' onChange={e => setAccess(prevState => ({...prevState, promocode: e.target.value}))} placeholder=" "/>
                                <label for="promocode" className="placeholder">Промокод</label>
                            </div>
                            <div className='button' onClick={check_promocode}>
                                Проверить
                            </div>
                        </div>
                    </form>
                    <p className='access_modal-price'>Цена: <span id='price'>{price}</span> <h3>₽</h3></p>
                    <form className='access_modal-form'>
                        <p>Введите никнейм в майнкрафте</p>
                        <div className='input-container'>
                            <input id="nick" value={access.nick} type='text' onChange={e => setAccess(prevState => ({...prevState, nick: e.target.value}))} placeholder=" "/>
                            <label for="nick" className="placeholder">Никнейм</label>
                        </div>
                    </form>
                    <p>Присоединитесь к нашему дискорд серверу</p>
                    <div className='access_modal-discord' onClick={() => window.open("https://discord.gg/6v76XcYh7s")}>
                        <div>
                            <p>ZeroShit</p>
                            <h3>Дискорд сервер</h3>
                        </div>
                        <BiLinkExternal className='access_modal-discord_arrow'/>
                    </div>
                    <div className='button' onClick={buy}>Оплатить</div>
                </div>
            </div>
        </div>
  )
}

export default Guest