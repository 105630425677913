import React, { useContext, useState } from 'react'
import Footer from "../components/Footer";
import MSIcon from "../img/Ziroshit.png";
import Header from '../components/Header';
import { useNavigate } from 'react-router-dom';
import { Context } from '..';
import { GUEST_ROUTE } from '../utils/consts';

const Main = () => {
  const navigate = useNavigate()
  const {user} = useContext(Context)
  const [text, setText] = useState("Скачать лаунчер")

  const downloadFile = async (fileName = 'ZeroShitLauncher.exe' ) => {
    setText("Скачивание...")
    fetch('https://backend.zerosquad.fun/minecraft/launcher/download', {
      method: 'GET',
      headers: {
        'Content-Type': 'application/exe',
      },
    })
      .then(response => response.blob())
      .then(blob => {
        const url = window.URL.createObjectURL(new Blob([blob]));

        const link = document.createElement('a');
        link.href = url;
        link.download = fileName;

        document.body.appendChild(link);

        link.click();

        link.parentNode.removeChild(link);
        setText("Скачать лаунчер")
      });
      
  };
  return (
    <div className='MineShit'>
      <title>ZeroShit - ванильный сервер майнкрафт!</title>
      <Header/>
      <div className='glow_header'/>
      <section className="information">
        <h1 className="information_title">🥵Крутой🥵 Cервер Mайнкрафт</h1>
        <p className="information_description">ZeroShit - уникальный сервер. Без всякой хуйни для лохов ебаных.</p>
        <img className="information_MSIcon" src={MSIcon} alt=""/>
        <div className='information_buttons'>
          {user.user.authorization === "false" &&(
            <p onClick={() => navigate(GUEST_ROUTE)} className='join_button'>Купить проходку</p>
          )}
          {user.user.authorization !== "true" && user.user.authorization !== "false" &&(
            <p onClick={() => window.location.replace("https://discord.com/oauth2/authorize?client_id=1206144488750190653&response_type=code&redirect_uri=https%3A%2F%2Fzerosquad.fun%2Fauthorization&scope=identify+guilds+guilds.join")} className='join_button'>Купить проходку</p>
          )}
          {user.user.authorization === "true" && user.user.authorization !== "false" &&(
            <p className='join_button' onClick={() => downloadFile()} id="download_launcher">{text}</p>
          )}
        </div>
      </section>
      
      <Footer/>
    </div>
    
  )
}

export default Main