import React from 'react'
import { IoHome, IoWallet } from 'react-icons/io5';
import { IoPeople, IoMap, IoSettings} from "react-icons/io5";
import { FaBalanceScale, FaDonate } from 'react-icons/fa';
import { useLocation, useNavigate } from 'react-router-dom';
import { USERS_ROUTE, COURT_ROUTE, DONATE_ROUTE, SETTINGS_ROUTE, PROFILE_ROUTE, WALLET_ROUTE, MAIN_ROUTE, AUTH_MAP_ROUTE } from '../utils/consts';

export default function HeaderIsAuth() {
  const navigate = useNavigate()
  const location = useLocation();

  return (
    <header className='header_auth'>
        <h1 onClick={() => navigate(MAIN_ROUTE)}>ZeroShit</h1>
        <div className='header_auth-main'>
          
            <div onClick={() => navigate(PROFILE_ROUTE)} className={(location.pathname === PROFILE_ROUTE ? 'active' : '')}>
              <IoHome className='header_auth-main_icon'/>
            </div>
            <div onClick={() => navigate(WALLET_ROUTE)} className={(location.pathname === WALLET_ROUTE ? 'active' : '')}>
              <IoWallet className='header_auth-main_icon'/>
            </div>
            <div onClick={() => navigate(USERS_ROUTE)} className={(location.pathname === USERS_ROUTE ? 'active' : '')}>
              <IoPeople className='header_auth-main_icon'/>
            </div>
            <div onClick={() => navigate(COURT_ROUTE)} className={(location.pathname === COURT_ROUTE ? 'active' : '')}>
              <FaBalanceScale className='header_auth-main_icon'/>
            </div>
            <div onClick={() => navigate(AUTH_MAP_ROUTE)} className={(location.pathname === AUTH_MAP_ROUTE ? 'active' : '')}>
              <IoMap className='header_auth-main_icon'/>
            </div>
            <div onClick={() => navigate(DONATE_ROUTE)} className={(location.pathname === DONATE_ROUTE ? 'active' : '')}>
              <FaDonate className='header_auth-main_icon'/>
            </div>
            <div onClick={() => navigate(SETTINGS_ROUTE)} className={(location.pathname === SETTINGS_ROUTE ? 'active' : '')}>
              <IoSettings className='header_auth-main_icon'/>
            </div>
        </div>
    </header>
  )
}
