import {makeAutoObservable} from "mobx";

export default class CourtStore {
    constructor() {
        this._isCourt = false
        this._courts = []
        this._modalCourt = []
        makeAutoObservable(this)
    }

    setIsCourt(bool) {
        this._isCourt = bool
    }
    setCourts(courts) {
        this._courts = courts
    }
    setModalCourts(modalCourt) {
        this._modalCourt = modalCourt
    }

    get isCourt() {
        return this._isCourt
    }
    get courts() {
        return this._courts
    }
    get modalCourts() {
        return this._modalCourt
    }
}
