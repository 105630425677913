import React, { useContext, useEffect, useState } from 'react'
import { FaSpinner } from 'react-icons/fa'
import { IoIosArrowBack } from 'react-icons/io'
import { useNavigate, useParams } from 'react-router-dom'
import { Context } from '..'
import HeaderIsAuth from '../components/HeaderIsAuth'
import { checkCourt } from '../http/courtAPI'
import { COURT_ROUTE } from '../utils/consts'

const CourtPage = () => {
    const {court} = useContext(Context)
    const [loading, setLoading] = useState(true)
    const navigate = useNavigate()
    const {id} = useParams()

    useEffect(() => {
        var select = Number(id)
        checkCourt().then(data => {
            if (data == null) {
              
            } else {
              const Court = data.filter(function(court) {
                return court.number === select
              })
              court.setModalCourts(Court)
            }
          }).finally(() => setLoading(false))
      // eslint-disable-next-line
      }, [])
    
      if (loading) {
        return (
          <>
            <HeaderIsAuth/>
            <div className='loading'>
            <FaSpinner/> 
            </div>
        </>
        )
      }
  return (
    <>
        <HeaderIsAuth/>
        <div className='court-page'>
            <div className='court-page__back'>
                <IoIosArrowBack className='court-page__back-arrow' onClick={() => navigate(COURT_ROUTE)}/>
                <h2 className='court-page__back-button' onClick={() => navigate(COURT_ROUTE)}>Назад</h2>
                <h2 className='court-page__back-info'>Дело №{court.modalCourts.map(item => item.number)} {court.modalCourts.map(item => item.list)}</h2>
            </div>
            {court.modalCourts.map(item =>
                <div className='court-page__modal' key={item.number}>
                    <div className='court-page__modal-header'>
                        <div className='court-page__modal-header__suinger'>
                            <h2>Подающий</h2>
                            <div className='court-page__modal-header-suinger'>
                                <img src="" alt=""/>
                                <h3>{item.suinger}</h3>
                            </div>
                        </div>
                        <div className='court-page__modal-header__accused'>
                            <h2>Обвиняемый</h2>
                            <div className='court-page__modal-header-accused'>
                                <img src="" alt=""/>
                                <h3>{item.accused}</h3>
                            </div> 
                        </div>
                    </div>
                <div className='court-page__modal-description'>
                    <h2>Описание</h2>
                    {item.description}
                </div>
                <div className='court-page__modal-proof'>
                    <h2>Доказательства</h2>
                    {item.proof}
                </div>
            </div>
            )}
            
        </div>
    </>
  )
}

export default CourtPage