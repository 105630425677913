import React from 'react'
import Header from '../components/Header'

const Contacts = () => {
  return (
    <div>
      <title>MineShit ੶ Контактные данные</title>
      <Header/>
      <div className='Contacts'>
          <h1>Контактные данные</h1>
          <h3>Дискорд: lalolog_</h3>
      </div>
    </div>
  )
}

export default Contacts