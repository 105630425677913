import { observer } from 'mobx-react-lite'
import React, { useContext, useEffect, useState } from 'react'
import { FaSpinner } from 'react-icons/fa'
import { Context } from '..'
import HeaderIsAuth from '../components/HeaderIsAuth'
import { addShopIcons } from '../http/shopAPI'
import { addAdminState, adminState, updateUser, users } from '../http/userAPI'

const Admin = observer(() => {
    const [loading, setLoading] = useState(true)
    const {user} = useContext(Context)
    const [nick, setNick] = useState('')
    const [name, setName] = useState('')
    const [img, setImg] = useState('')
    const [walletBuild, setWalletBuild] = useState('')
    const [walletBuildMsg, setWalletBuildMsg] = useState('')
    const [courtBuild, setCourtBuild] = useState('')
    const [courtBuildMsg, setCourtBuildMsg] = useState('')
    const [shopBuild, setShopBuild] = useState('')
    const [shopBuildMsg, setShopBuildMsg] = useState('')
    const [nickMAYOR, setNickMAYOR] = useState('')
    const [nickREFERE, setNickREFERE] = useState('')
    //const [role, setRole] = useState('')

    useEffect(() => {
        adminState().then(data => {
            setWalletBuild(data.wallet_build)
            setWalletBuildMsg(data.wallet_build_msg)
            setCourtBuild(data.court_build)
            setCourtBuildMsg(data.court_build_msg)
            setShopBuild(data.shop_build)
            setShopBuildMsg(data.shop_build_msg)
        })
        users().then(data => {
            user.setUsers(data)
        }).finally(() => setLoading(false))
        // eslint-disable-next-line
      }, [])

    // eslint-disable-next-line  
    let data
    const userUpdateMayor = async () => {
        try {
          data = await updateUser(nickMAYOR, 'MAYOR')
          alert('Успешно')
          window.location.reload();
        } catch (err) {
          alert(err.response.data.message)
        }
    }

    const shopIconsAdd = async () => {
        try {
          data = await addShopIcons(img, name)
          alert('Успешно')
          window.location.reload();
        } catch (err) {
          alert(err.response.data.message)
        }
    }

    const AdminStateAdd = async () => {
        try {
          data = await addAdminState(walletBuild, walletBuildMsg, courtBuild, courtBuildMsg, shopBuild, shopBuildMsg)
          alert('Успешно')
          window.location.reload();
        } catch (err) {
          alert(err.response.data.message)
        }
    }

    const userUpdateReferee = async () => {
        try {
          data = await updateUser(nickREFERE, 'REFEREE')
          alert('Успешно')
          window.location.reload();
        } catch (err) {
          alert(err.response.data.message)
        }
    }

    if (loading) {
    return (
        <div>
        <HeaderIsAuth/>
        <div className='loading'>
            <FaSpinner/> 
        </div>
    </div>
    )
    }

  return (
    <div>
        <HeaderIsAuth/>
        <div className='admin'>
            <h1 className='admin-title'>Админ панель</h1>
            <div className='admin-list'>
                <div className='admin-city'>
                    <form>
                        <h2 className='admin-court-title'>Назначить мэра</h2>
                        <select value={nickMAYOR} onChange={e => setNickMAYOR(e.target.value)}>
                            <option key="-" disabled value="">Игрок</option>
                            {user.users.map(item =>
                            <option key={item._id} value={item.nick}>{item.nick}</option>
                            )}
                        </select>
                        <div className='admin-court-buttons'>
                            <div className='auth-sumbit'>Список Мэров</div>
                            <div className='auth-sumbit' onClick={userUpdateMayor}>Назначить</div>
                        </div>
                    </form>
                </div>
                <div className='admin-court'>
                    <form>
                        <h2 className='admin-court-title'>Судья: Lalolog_</h2>
                        <select value={nickREFERE} onChange={e => setNickREFERE(e.target.value)}>
                            <option key="--" disabled value="">Игрок</option>
                            {user.users.map(item =>
                            <option key={item._id} value={item.nick}>{item.nick}</option>
                            )}
                        </select>
                        <div className='auth-sumbit' onClick={userUpdateReferee}>Назначить</div>
                    </form>
                </div>
                <div className='admin-hotbar'>
                <h2>Панель быстрого доступа</h2>
                <select>
                    <option disabled value="">Действие</option>
                    <option>Забанить</option>
                </select>
                <select value={nick} onChange={e => setNick(e.target.value)}>
                    <option key="--" disabled value="">Игрок</option>
                    {user.users.map(item =>
                    <option key={item._id} value={item.nick}>{item.nick}</option>
                    )}
                </select>
                <div className='auth-sumbit'>Выполнить</div>        
            </div>
                <div className='modal-Shop__content'>
                    <form>
                        <input type="text" value={img} onChange={e => setImg(e.target.value)} placeholder="Картинка"/>
                        <input type="text" value={name} onChange={e => setName(e.target.value)} placeholder="Название"/>
                        <div className='auth-sumbit' onClick={shopIconsAdd}>Добавить</div>
                        <input type="text" value={walletBuild} onChange={e => setWalletBuild(e.target.value)} placeholder="Кошелек"/>
                        <input type="text" value={walletBuildMsg} onChange={e => setWalletBuildMsg(e.target.value)} placeholder="Кошелек"/>
                        <input type="text" value={courtBuild} onChange={e => setCourtBuild(e.target.value)} placeholder="Суд"/>
                        <input type="text" value={courtBuildMsg} onChange={e => setCourtBuildMsg(e.target.value)} placeholder="Суд"/>
                        <input type="text" value={shopBuild} onChange={e => setShopBuild(e.target.value)} placeholder="Магазин"/>
                        <input type="text" value={shopBuildMsg} onChange={e => setShopBuildMsg(e.target.value)} placeholder="Магазин"/>
                        <div className='auth-sumbit' onClick={AdminStateAdd}>Обновить</div>
                    </form>
                </div>
            </div>
        </div>
    </div>
  )
})

export default Admin