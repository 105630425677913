import React, {createContext} from 'react';
import ReactDOM from 'react-dom/client';
import './styles/index.css';
import './styles/App.css';
import './styles/Header.css';
import './styles/Footer.css';
import './styles/Information.css';
import './styles/Questions.css';
import './styles/Auth.css';
import './styles/SideBar.css';
import './styles/Profile.css';
import './styles/Court.css';
import './styles/Wallet.css';
import './styles/Users.css';
import './styles/Admin.css';
import './styles/Shop.css';
import './styles/Donate.css';
import './styles/Settings.css';
import './styles/Dogovor.css';
import './styles/Contacts.css';
import './styles/Map.css';
import './styles/Statistic.css';
import './styles/Wiki.css';
import './styles/Authorization.css';
import './styles/Dropdown.css';
import './styles/Guest.css';
import './styles/Fresh.css';
import App from './App';
import UserStore from './store/UserStore';
import CardStore from './store/CardStore';
import CourtStore from './store/CourtStore';
import ShopStore from './store/ShopStore';

export const Context = createContext(null)

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Context.Provider value={{
    user: new UserStore(),
    card: new CardStore(),
    court: new CourtStore(),
    shop: new ShopStore(),
  }}>
    <App />
  </Context.Provider>
);