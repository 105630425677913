import React, { useContext, useEffect, useState } from 'react'
import { Context } from '../index';
import HeaderIsAuth from '../components/HeaderIsAuth'
import { addCourt, checkCourt, updateCourt } from '../http/courtAPI';
import { FaSpinner } from 'react-icons/fa';
import { adminState, users } from '../http/userAPI';
import { addInvoice } from '../http/cardAPI';
import { COURT_ROUTE } from '../utils/consts';
import { useNavigate } from 'react-router-dom';
import { IoArrowForward } from 'react-icons/io5';

function Court() {
    const {court, user} = useContext(Context)
    const navigate = useNavigate()
    const [loading, setLoading] = useState(true)
    const [accused, setAccused] = useState('')
    const [build, setBuild] = useState(true)
    const [description, setDescription] = useState('')
    const [proof, setProof] = useState('')
    const [list, setList] = useState('')
    const [number, setNumber] = useState('')
    const [decision, setDecision] = useState('')
    const [invoiceTo, setInvoiceTo] = useState('')
    const [invoiceFrom, setInvoiceFrom] = useState('')
    const [invoiceAmount, setInvoiceAmount] = useState('')
    
    const [modalAddCourt, setModalAddCourt] = useState(false);
    const toggleModalAddCourt = () => {
        setModalAddCourt(!modalAddCourt)
    }
    const [modalUpdateCourt, setModalUpdateCourt] = useState(false);
    const toggleModalUpdateCourt = () => {
        setModalUpdateCourt(!modalUpdateCourt)
    }

    useEffect(() => {
      adminState().then(data => {
        setBuild(data.court_build)
      })
      checkCourt().then(data => {
        if (data == null) {
          
        } else {
          const Court = data.filter(function(court) {
            return court.bool === 'false' 
          })
          court.setCourts(Court)
        }
      }).finally(() => setLoading(false))
      users().then(data => {
        if (data == null){
        } else {
          const User = data.filter(function(user) {
            return user.auth === 'true' 
          })
          user.setUsers(User)
        }
      })
      // eslint-disable-next-line
    }, [])
    
    // eslint-disable-next-line
    let data
    const CourtAdd = async () => {
      try {
        data = await addCourt(accused, description, proof, list)
        alert('Успешно')
        window.location.reload();
      } catch (err) {
        alert(err.response.data.message)
      }
    }
    const CourtUpdate = async () => {
      try {
        data = await updateCourt(number, decision)
        data = await addInvoice(decision, invoiceTo, invoiceFrom, invoiceAmount)
        alert('Успешно')
        window.location.reload();
      } catch (err) {
        alert(err.response.data.message)
      }
    }

    if (build === 'true') {
      return (
        <>
          <HeaderIsAuth/>
          <div className='loading'>
            <h1>Суд Не Построен</h1>
          </div>
        </>
      )
    }

    if (loading && build !== 'true') {
      return (
        <>
          <HeaderIsAuth/>
          <div className='loading'>
          <FaSpinner/> 
          </div>
        </>
      )
    }

    //function selectCourt(select) {
    //  checkCourt().then(data => {
    //    if (data == null) {
    //      
    //    } else {
    //      setModalCourt(!modalCourt)
    //      const Court = data.filter(function(court) {
    //        return court.number === select
    //      })
    //      court.setModalCourts(Court)
    //    }
    //  })
    //}

    function CourtsUser() {
      checkCourt().then(data => {
        if (data == null) {
          
        } else {
          const Court = data.filter(function(court) {
            return court.bool === 'true' 
          })
          court.setCourts(Court)
        }
      })
    }

  return (
    <>
      <HeaderIsAuth/>
      <div className='court'>
        <div className='court-btns'>       
          {user.user.roles.includes('REFEREE') && (
            <div className='court-btn-solution' onClick={toggleModalUpdateCourt}>Решение суда</div>
          )}
          {user.user.roles.includes('ADMIN') && (
            <div className='court-btn-solved-cases' onClick={CourtsUser}>Решенные дела</div>
          )}
          <div className='court-btn' onClick={toggleModalAddCourt}>Подать в суд</div>
        </div>   
        <div className='subpoena-list'>
            {court.isCourt || court.courts.map(item =>
              <div className='subpoena' key={item.number}>
                  <div className='subpoena-first-line'>
                    <div className='subpoena-first-line_suinger'>
                      <img className='subpoena-avatar' src={item.avatar_mini} alt=''/>
                      <h2>{item.suinger}</h2>
                    </div>
                    <IoArrowForward className='subpoena-first-line_arrow'/>
                    <div className='subpoena-first-line_accused'>
                      <img className='subpoena-avatar' src={item.avatar_mini_acc} alt=''/>
                      <h2>{item.accused}</h2>
                    </div>
                  </div>
                  <p>{item.description}</p>
                  <div className='subpoena-second-line'>
                    <span>№{item.number}</span>
                    <div className='subpoena-more' onClick={() => navigate(COURT_ROUTE + "/" + item.number)}>Подробнее</div>
                  </div> 
              </div>         
            )}          
            {court.isCourt && court.courts.map(item =>
              <div className='subpoena' key={item.number}>
                <div className='subpoena-first-line'>
                  <div className='subpoena-first-line_suinger'>
                    <img className='subpoena-avatar' src={item.avatar_mini} alt=''/>
                    <h2>{item.suinger}</h2>
                  </div>
                  <IoArrowForward className='subpoena-first-line_arrow'/>
                  <div className='subpoena-first-line_accused'>
                    <img className='subpoena-avatar' src={item.avatar_mini_acc} alt=''/>
                    <h2>{item.accused}</h2>
                  </div>
                </div>
                <p>{item.description}</p>
                <div className='subpoena-second-line'>
                  <span>№{item.number}</span>
                  <div className='subpoena-more' onClick={() => navigate(COURT_ROUTE + "/" + item.number)}>Подробнее</div>
                </div> 
              </div>        
            )}
        </div>
      </div>
      {modalAddCourt && (
        <div className='modal-Court'>
          <div className='modal-Court__content'>
            <div className='modal-Court__content-back' onClick={toggleModalAddCourt}>Отмена</div>
            <h2>Подать в Суд</h2>
            <form>
              <select value={accused} onChange={e => setAccused(e.target.value)}>
                <option key="1" value="" disabled>Выберите обвиняемого</option>
                {user.users.map(item =>
                  <option key={item._id} value={item.nick}>{item.nick}</option>
                )}
              </select>
              <div className='input-container'>
                <input id="information" type="text" value={description} onChange={e => setDescription(e.target.value)} placeholder=" "></input>
                <label for="information" className="placeholder">Краткая информация</label>
              </div>
              <select value={list} onChange={e => setList(e.target.value)}>
                <option className='disabled-option' value="" disabled>Выберите категорию</option>
                <option>Воровство</option>
                <option>Гриф</option>
                <option>Другое</option>
              </select>
              <textarea type="text" value={proof} onChange={e => setProof(e.target.value)} placeholder="Координаты приступления / Доказательства"></textarea>
              <div className="auth-sumbit" onClick={CourtAdd}>Подать</div>
            </form>
          </div>
        </div>
      )}
      {modalUpdateCourt && (
        <div className='modal-Court'>
          <div className='modal-Court__content-solution'>
            <div className='modal-Court__content-back' onClick={toggleModalUpdateCourt}>Отмена</div>
            <h2>Решение суда</h2>
            <form>
              <select value={number} onChange={e => setNumber(e.target.value)}>
                <option key="number" value="" disabled>Выберите номер заявки</option>
                {court.courts.map(item =>
                  <option key={item._id} value={item.number}>{item.number}</option>
                )}
              </select>
              <textarea type="text" value={decision} onChange={e => setDecision(e.target.value)} placeholder="Решение суда"/>
              <p className='modal-Court__content-p'>Инвойс</p>
              <div className='modal-Court__content-select'>
                <select value={invoiceTo} onChange={e => setInvoiceTo(e.target.value)}>
                <option key="invoiceTo" value="" disabled>Получатель</option>
                {user.users.map(item =>
                  <option key={item._id} value={item.nick}>{item.nick}</option>
                )}
              </select>
              <select value={invoiceFrom} onChange={e => setInvoiceFrom(e.target.value)}>
                <option key="invoiceFrom" value="" disabled>Отправитель</option>
                {user.users.map(item =>
                  <option key={item._id} value={item.nick}>{item.nick}</option>
                )}
              </select>
              </div>
              <div className='input-container'>
                <input id="amount" value={invoiceAmount} onChange={e => setInvoiceAmount(e.target.value)} placeholder=' '/>
                <label for="amount" className="placeholder">Сумма</label>
              </div>
              
              <div className="auth-sumbit" onClick={CourtUpdate}>Решить</div>
            </form>
            
          </div>
        </div>
      )}
    </>
  )
}

export default Court