import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { GUEST_ROUTE } from '../utils/consts';

const Fresh = () => {
    const navigate = useNavigate()
    const [text, setText] = useState("СКАЧАТЬ")
  
    const downloadFile = async (fileName = 'ZeroShitLauncher.exe' ) => {
      setText("СКАЧИВАНИЕ...")
      fetch('https://backend.zerosquad.fun/minecraft/launcher/download', {
        method: 'GET',
        headers: {
          'Content-Type': 'application/exe',
        },
      })
        .then(response => response.blob())
        .then(blob => {
          const url = window.URL.createObjectURL(new Blob([blob]));
  
          const link = document.createElement('a');
          link.href = url;
          link.download = fileName;
  
          document.body.appendChild(link);
  
          link.click();
  
          link.parentNode.removeChild(link);
          setText("СКАЧАТЬ")
        });
        
    };
    return (
      <div className='Fresh'>
        <title>ZeroShit - ванильный сервер майнкрафт!</title>
        <section className="Fresh-information">
            <h1>СЕРВЕР МАЙНКРАФТ ZERO</h1>
            <h3>КУПИ И И ГРАЙ С ДРУЗЬЯМИ!</h3>
          <div className='Fresh-information_buttons'>
            <p onClick={() => navigate(GUEST_ROUTE)} className='Fresh-button Fresh-buy'>ПОКУПКА</p>
            <p className='Fresh-button Fresh-download' onClick={() => downloadFile()} id="download_launcher">{text}</p>
          </div>
        </section>
      </div>
      
    )
}

export default Fresh