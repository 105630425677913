import React, { useContext } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Context } from '..';
import { GUEST_ROUTE, MAIN_ROUTE } from '../utils/consts';
import { IoLogInOutline } from 'react-icons/io5';

const Header = () => {
    const navigate = useNavigate()
    const {user} = useContext(Context)
    const location = useLocation();
    return (
        <header className='header'>
            <div className='header_div'>
                <div className='header_title'>
                    
                    <span onClick={() => navigate(MAIN_ROUTE)} className={(location.pathname === MAIN_ROUTE ? 'no-select' : '')}>ZeroShit</span>
                </div>
                
                {user.user.authorization === "false" &&(
                    <div onClick={() => navigate(GUEST_ROUTE)} className='header_login'>Купить проходку</div>
                )}
                {user.user.authorization !== "true" && user.user.authorization !== "false" &&(
                    <div onClick={() => window.location.replace("https://discord.com/oauth2/authorize?client_id=1206144488750190653&response_type=code&redirect_uri=https%3A%2F%2Fzerosquad.fun%2Fauthorization&scope=identify+guilds+guilds.join")} className='header_login'><IoLogInOutline className='header_login-icon'/>АВТОРИЗОВАТЬСЯ</div>
                )}
                {user.user.authorization === "true" &&(
                    <div className='header_profile' onClick={() => navigator.clipboard.writeText(localStorage.getItem('token'))}/>
                )}
            </div>
        </header>
    );
};
                /*
                <div className='header_options'>
                    <h2 className={(location.pathname === WIKI_ROUTE || location.pathname === WIKI_ROUTE + "/start" || location.pathname === WIKI_ROUTE + "/rules" || location.pathname === WIKI_ROUTE + "/dependence" ? 'header_options_active' : '') || (location.pathname === STATISTIC_ROUTE ? ' header_options_reverse' : '') || (location.pathname === MAP_ROUTE ? ' header_options_new' : '') || (location.pathname === MAIN_ROUTE ? ' header_options_new' : '') || (location.pathname === GUEST_ROUTE ? ' header_options_new' : '')} onClick={() => navigate(WIKI_ROUTE)}>Вики</h2>
                    <h2 className={(location.pathname === STATISTIC_ROUTE ? 'header_options_active header_options_reverse' : '') || (location.pathname === MAP_ROUTE ? ' header_options_reverse' : '') || (location.pathname === MAIN_ROUTE ? ' header_options_new' : '') || (location.pathname === GUEST_ROUTE ? ' header_options_new' : '')} onClick={() => navigate(STATISTIC_ROUTE)}>Статистика</h2>
                    <h2 className={(location.pathname === MAP_ROUTE ? 'header_options_active header_options_new' : '') || (location.pathname === WIKI_ROUTE ? ' header_options_new' : '') || (location.pathname === MAIN_ROUTE ? ' header_options_new' : '') || (location.pathname === GUEST_ROUTE ? ' header_options_new' : '')} onClick={() => navigate(MAP_ROUTE)}>Карта</h2>
                </div>
                */
export default Header;