import React from 'react';
import { useNavigate } from 'react-router-dom';
import { CONTACTS_ROUTE, DOGOVOR_ROUTE, FRESH_ROUTE } from '../utils/consts';

const Footer = () => {
    const navigate = useNavigate()
    return (
        <footer>
            <div className='footer'>
                <div className='footer_copyright'>
                    <h2>&copy;  Zero<span onClick={() => navigate(FRESH_ROUTE)}>Shit</span> 2024</h2>
                    <p>Сервер не относитьься к Mojang Studios.</p>
                </div>
                <a href="https://freekassa.ru" target="_blank" rel="noopener noreferrer">
                    <img src="https://cdn.freekassa.ru/banners/big-dark-2.png" title="Прием платежей на сайте" alt=''/>
                </a>
                <div>
                    <h3 onClick={() => navigate(DOGOVOR_ROUTE)}>Договор-оферта</h3>
                </div>
                <div>
                    <h3 onClick={() => navigate(CONTACTS_ROUTE)}>Контакты</h3>
                </div>
            </div>
        </footer>
    );
};

export default Footer;