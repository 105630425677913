import React, { useContext, useEffect, useState } from 'react'
import { Context } from '../index'
import HeaderIsAuth from '../components/HeaderIsAuth'
import { observer } from 'mobx-react-lite'
import { FaDiscord } from 'react-icons/fa'
import { authentication } from '../http/userAPI'
import { IoHeart } from 'react-icons/io5';

const Profile = observer(() => {
  const {user} = useContext(Context)
  const [loading, setLoading] = useState(false)
  useEffect(() => {
    authentication().then(data => {
      if (data !== null) {
        user.setUser(data.data)
        user.setIsAuth(data.data.authorization)
      }
    }).finally(() => setLoading(false))
    
  // eslint-disable-next-line
  }, [])

  if (loading) {
    return (
    <>
      <title>MineShit ੶ Профиль</title>
      <HeaderIsAuth/>
      <div className='loading'>
        <div className="dot-spinner">
          <div className="dot-spinner__dot"></div>
          <div className="dot-spinner__dot"></div>
          <div className="dot-spinner__dot"></div>
          <div className="dot-spinner__dot"></div>
          <div className="dot-spinner__dot"></div>
          <div className="dot-spinner__dot"></div>
          <div className="dot-spinner__dot"></div>
          <div className="dot-spinner__dot"></div>
        </div>
      </div>
    </>
    )
  }

  return (
    <>
      <title>MineShit ੶ Профиль</title>
      <HeaderIsAuth/>
      <div className='profile'>
        <div className='profile_header'>
          <div className='profile_cover'></div>
          <div className='profile_header-footer'>
            <img className="profile_avatar" src={user.user.avatar} alt=""/>
            <h2 className='profile_header-name'>{user.user.minecraft_nick}</h2>
          </div>
        </div>
        <div className='profile_information'>
          <div className='profile_information-blocks'>
            <div className='profile_information-list'>
              {user.user.description !== "" && user.user.family !== "" && (
                <div className='profile_information-list_inf'>
                  <h1>Краткая информация</h1>
                  {user.user.description !== "" && (
                    <div className='profile_information-list_inf-description'>
                      <h3>{user.user.description}</h3>
                    </div>
                  )}
                  {user.user.family !== "" && (
                    <div className='profile_information-list_inf-family'>
                      <IoHeart className='profile_information-list_inf-family_icon'/>
                      <h3>{user.user.family}</h3>
                    </div>
                  )}
                </div>
              )}
              <div className='profile_information-list_social'>
                <h1>Соц. Сети</h1>
                <div className='profile_information-list_social-discord'>
                  <FaDiscord className='profile_information-list_social-discord_icon'/>
                  <h3>{user.user.discord_nick}</h3>
                </div>
              </div>
              <div className='profile_information-list_cards'>
                <h1>Карты</h1>
              </div>
            </div>
            <div className='profile_information-blog'>
              <div className='profile_information-blog_add'>
                <img src={user.user.avatar} alt=""/>
                <input placeholder="Поделитесь, как вы загриферили мэрию?"></input>
              </div>
            </div>
          </div>
        </div>
      </div> 
    </>
  )
})



export default Profile