import {makeAutoObservable} from "mobx";

export default class CityStore {
    constructor() {
        this._isShop = false
        this._products = []
        this._shopIcons = []
        this._selectedProduct = []
        makeAutoObservable(this)
    }

    setIsShop(bool) {
        this._isShop = bool
    }
    setProducts(products) {
        this._products = products
    }
    setShopIcons(shopIcons) {
        this._shopIcons = shopIcons
    }
    setSelectedProduct(product) {
        this._selectedProduct = product
    }


    get isShop() {
        return this._isShop
    }
    get products() {
        return this._products
    }
    get shopIcons() {
        return this._shopIcons
    }
    get selectedProduct() {
        return this._selectedProduct
    }
}