import React from 'react'
import { AreaChart, Area, XAxis, ReferenceLine, Tooltip, ResponsiveContainer } from 'recharts';

const StatisticDiagram = () => {
    const statistic = [
        { name: '00:00', Игроков: 8 },
        { name: '01:00', Игроков: 9 },
        { name: '02:00', Игроков: 5 },
        { name: '03:00', Игроков: 1 },
        { name: '04:00', Игроков: 0 },
        { name: '05:00', Игроков: 0 },
        { name: '06:00', Игроков: 0 },
        { name: '07:00', Игроков: 0 },
        { name: '08:00', Игроков: 0 },
        { name: '09:00', Игроков: 0 },
        { name: '10:00', Игроков: 1 },
        { name: '11:00', Игроков: 1 },
        { name: '12:00', Игроков: 5 },
        { name: '13:00', Игроков: 10 },
        { name: '14:00', Игроков: 9 },
        { name: '15:00', Игроков: 8 },
        { name: '16:00', Игроков: 7 },
        { name: '17:00', Игроков: 6 },
        { name: '18:00', Игроков: 5 },
        { name: '19:00', Игроков: 9 },
        { name: '20:00', Игроков: 9 },
        { name: '21:00', Игроков: 10 },
        { name: '22:00', Игроков: 10 },
        { name: '23:00', Игроков: 10 },
    ]

    const CustomTooltip = ({ active, payload, label }) => {
        if (active && payload && payload.length) {
          return (
            <div className="statistic_diagram-tooltip">
              <p className="label">{`Время: ${label} МСК`}</p>
              {payload.map((entry, index) => (
                <p key={`data-${index}`} className="data">
                  {`Игроков: ${entry.value}`}
                </p>
              ))}
            </div>
          );
        }
        return null;
    };

    return (
        <div className='statistic'>
            <ResponsiveContainer width="101.5%" height="102%">
                <AreaChart data={statistic} className='statistic_diagram'>
                    <ReferenceLine x="Page C" stroke="green" label="Min PAGE" />
                    <ReferenceLine y={4000} label="Max" stroke="red" strokeDasharray="3 3" />
                    
                    <XAxis dataKey="name" width={0} height={0}/>
                    <Tooltip content={<CustomTooltip/>}/>
                    <Area type="monotone" dataKey="Игроков" stroke="#393949" fill="#2B2B38"  strokeWidth={3}/>
                </AreaChart>
            </ResponsiveContainer>
        </div>
    )
}

export default StatisticDiagram