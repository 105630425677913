import React, { useLayoutEffect } from 'react'
import Header from '../components/Header'
import { IoArrowForward, IoList } from 'react-icons/io5'
import { useNavigate } from 'react-router-dom';
import { WIKI_ROUTE } from '../utils/consts';
import WikiSideBar from '../components/WikiSideBar';

const Wiki = () => {
  const navigate = useNavigate()
  const scrollToTargetElement = (targetElement) => {
    const element = document.getElementById(targetElement);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  }

  useLayoutEffect(() => {
    const targetElement = decodeURI(window.location.hash)
    const element = document.getElementById(targetElement.slice(targetElement.lastIndexOf('#') + 1));
    if (element) {
        element.scrollIntoView({ behavior: 'smooth', block: 'start' });
    } else {
        window.scrollTo(0, 0)
    }
  });

  return (
    <>
      <title>Вики ZeroShit ੶ Введение</title>
      <Header/>
      <div className='wiki'>
          <WikiSideBar/>
          <div className='wiki_content'>
            <h1 id='#intro'>Введение</h1>
            <p>Добро пожаловать на Вики сервера MineShit! Здесь вы найдете всю необходимую информацию, связанную с сервером!</p>
            <ul className='wiki_content-social'>
              <h2 id='social'>Соц. Сети</h2>
              <li>
                <span onClick={() => window.location.replace("https://discord.gg/SErH6saXxu")}>Discord</span>
              </li>
              <li>
                <span onClick={() => window.location.replace("https://vk.com/mineshitofficial")}>Вконтакте</span>
              </li>
              <li>
                <span onClick={() => window.location.replace("https://www.youtube.com/channel/UCA-BIEluxo7ESNLyeqYdmbg")}>YouTube</span>
              </li>
              <li>
                <span onClick={() => window.location.replace("https://www.tiktok.com/@mineshitofficial")}>TikTok</span>
              </li>
            </ul>
            <div className='wiki_content-next' onClick={() => navigate(WIKI_ROUTE + "/start")}>
              <div>
                <p>Далее</p>
                <h3>Начало</h3>
              </div>
              <IoArrowForward className='wiki_content-next_arrow'/>
            </div>
          </div>
          <div className='wiki_on_page'>
            <div className='wiki_on_page-sticky'>
              <h3>
                <IoList className='wiki_on_page-sticky_list'/>
                На этой странице
              </h3>
              <div className='wiki_on_page-sticky_navigation'>
                <div>
                  <span onClick={() => scrollToTargetElement('#intro')}>Введение</span>
                  <p onClick={() => scrollToTargetElement('#social')}><span>Соц. Сети</span></p>
                </div>
              </div>
            </div>
            
          </div>
      </div>
    </>
  )
}

export default Wiki