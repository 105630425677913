import { observer } from 'mobx-react-lite';
import React, { useContext, useEffect, useState } from 'react'
import { Context } from '../index';
import HeaderIsAuth from '../components/HeaderIsAuth'
import { checkInvoice, create_card, create_transaction, deleteInvoice, get_cards, get_transactions } from '../http/cardAPI';
import { IoCloseCircle } from 'react-icons/io5';
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from 'react-icons/md';
import Dropdown from '../components/Dropdown'

const Wallet = observer(() => {
  const [loading, setLoading] = useState(true)
  const [loadingTransaction, setLoadingTransaction] = useState()
  const [create_transaction_data, setCreate_transaction_data] = useState({sender_card_id: "", sender_card_title: "", sender_card_amount: "", recipient_card_id: "", recipient_card_title: "", amount: '', comment: ''})
  const [create_card_data, setCreate_card_data] = useState({title: "СберПанк", card_id: "11111", background: 'none'})
  const [wallet, setWallet] = useState(true);
  const [Transf, setTransf] = useState(false);

  const toggleTransf = () => {
    if (Transf) {
      setTransf(false)
      setWallet(true)
    } else {
      setTransf(true)
      setModalInvoices(false)
      setModalInvoice(false)
      setWallet(false)
    }
  }
  const [modalInvoice, setModalInvoice] = useState(false);
  const toggleInvoice = () => {
    if (modalInvoice) {
      setModalInvoice(false)
      setWallet(true)
    } else {
      setModalInvoice(true)
      setModalInvoices(false)
      setTransf(false)
      setWallet(false)
    }
  }
  const [modalInvoices, setModalInvoices] = useState(false);
  const toggleInvoices = () => {
      setModalInvoices(!modalInvoices)
  }
  const [modalInvoiceDescription, setModalInvoiceDescription] = useState(false);
  const toggleInvoiceDescription = () => {
      setModalInvoiceDescription(!modalInvoiceDescription)
  }

  const {card, user} = useContext(Context)

  const create_new_card = async () => {
    try {
      const data = await create_card(create_card_data)
      alert(data)
    } catch (err) {
      console.log(err)
      alert(err.response.data.message)
    }
  }

  useEffect(() => {
    get_cards().then(data => {
      if (data === null) {
        card.setIsCard(false)
      } else {
        card.setIsCard(true)
        card.setCards(data)
      }
    }).finally(() => setLoading(false))
    // eslint-disable-next-line
  }, [])

  function selectCard(select) {
    setLoadingTransaction('loading')
    get_cards().then(data => {
      if (data === null) {
        card.setIsCard(false)
      } else {
        card.setIsCard(true)
        card.setCards(data)
      }
    })
    console.log(select)
    get_transactions(select).then(data => {
      console.log("TRANSACTIONS", data)
      if (data == null) {
        card.setTransactions(data)
      } else if (data.hasOwnProperty('error')) {
        alert(data.error)
      } else {
        console.log(data)
        card.setTransactions(data)       
      }
    }).finally(() => setLoadingTransaction(false))
  }

  const transaction_create = async () => {
    try {
      setTransf(false)
      const data = await create_transaction(create_transaction_data)
      alert(data)
    } catch (err) {
      alert(err.response.data.message)
    }
  }

  const InvoiceTransactionAdd = async () => {
    try {
      //data = await addTransaction(card.selectedInvoices.map(item => item.amount).toString(), card.selectedInvoices.map(item => item.rec_card).toString(), sort_cardSender, "[Инвойс] " + card.selectedInvoices.map(item => item.description).toString())
      await deleteInvoice(card.selectedInvoices.map(item => item._id).toString())
      alert('Успешно')
      window.location.reload();
    } catch (err) {
      alert(err.response.data.message)
    }
  }

  function Invoices() {
    toggleInvoices()
    setLoadingTransaction('loading')
    checkInvoice().then(data => {
      if (data == null) {
        
      } else {
        const Invoices = data.filter(function(invoice) {
          return invoice.sender === user.user.nick
        })
        card.setInvoices(Invoices)       
      }
    }).finally(() => setLoadingTransaction(false))
  }

  function InvoicesModal(select) {
    toggleInvoiceDescription()
    checkInvoice().then(data => {
      if (data == null) {
        
      } else {
        const Invoices = data.filter(function(invoice) {
          return invoice.sender === user.user.nick
        })
        const Invoice = Invoices.filter(function(invoice) {
          return invoice._id === select
        })
        card.setSelectedInvoices(Invoice)       
      }
    })
  }

  if (loading) {
    return (
      <>
        <title>MineShit ੶ Кошелек</title>
        <HeaderIsAuth/>
        <div className='loading'>
          <div class="dot-spinner">
            <div class="dot-spinner__dot"></div>
            <div class="dot-spinner__dot"></div>
            <div class="dot-spinner__dot"></div>
            <div class="dot-spinner__dot"></div>
            <div class="dot-spinner__dot"></div>
            <div class="dot-spinner__dot"></div>
            <div class="dot-spinner__dot"></div>
            <div class="dot-spinner__dot"></div>
          </div>
        </div>
      </>
    )
  }

  if (card.isCard === false) {
    return (
      <>
        <title>MineShit ੶ Кошелек</title>
        <HeaderIsAuth/>
        <div className='wallet_create-card'>
          <h2>Создайте карту</h2>
          <form>
            <div className='input-container'>
              <input id="card-id" type="number" value={create_card_data.card_id} onChange={e => setCreate_card_data(prevState => ({...prevState, card_id: e.target.value}))} onKeyPress={(e) => { if (e.target.value.length >= 5) { e.preventDefault(); return false; }}} minLength={5} maxLength={5} placeholder=" "></input>
              <label for="card-id">Номер карты</label>
            </div>
            <div className='input-container'>
              <input id="card-title" type="text" value={create_card_data.title} onChange={e => setCreate_card_data(prevState => ({...prevState, title: e.target.value}))} placeholder=" "></input>
              <label for="card-title">Название карты</label>
            </div>
            <div className='button' onClick={create_new_card}>Создать карту</div>
          </form>
        </div>
      </>
    )
  }

  const handleOptionSelect = (option) => {
    console.log(`Selected option: ${option}`)
  }

  return (
    <>
      <title>MineShit ੶ Кошелек</title>
      <HeaderIsAuth/>
      <div className='wallet'>
        <div className='wallet_overlay'>
          <div className='wallet_card-list'>
            <div className='wallet_card-list_button' onClick={Invoices}>Инвойсы</div>
            <div className='wallet_card-list_cards'>
              {card.cards.map(item => 
                <div className='wallet-card' key={item._id} onClick={() => selectCard(item.card_id)}>
                  <h2>{item.title}</h2>
                  <div className='wallet-card_footer'>
                    <h3 className='wallet-card_footer-amount'>{item.amount} Алмазов</h3>
                    <h2>{item.card_id}</h2>
                  </div>
                </div>
              )}
            </div>
          </div>
          {wallet && (
            <div className='wallet-hui'>
              <div className='wallet-buttons'>
                <div className='wallet-button' onClick={toggleTransf}>Перевести</div>
                <div className='wallet-button' onClick={toggleInvoice}>Выставить счет</div>
                <div className='wallet-button' onClick={toggleTransf}>Снять наличные</div>
              </div>
              <div className='wallet-transactions-list'>
                <ol>
                  <div className='wallet-transaction-bar'>
                      <p className='wallet-transaction-nicks'>Игрок</p>
                      <p className='wallet-transaction-dates'>Дата</p>
                      <p className='wallet-transaction-amounts'>Сумма</p> 
                  </div>
                  {loadingTransaction === 'loading' &&(
                    <div className='wallet-loading'>
                      <div class="dot-spinner">
                        <div class="dot-spinner__dot"></div>
                        <div class="dot-spinner__dot"></div>
                        <div class="dot-spinner__dot"></div>
                        <div class="dot-spinner__dot"></div>
                        <div class="dot-spinner__dot"></div>
                        <div class="dot-spinner__dot"></div>
                        <div class="dot-spinner__dot"></div>
                        <div class="dot-spinner__dot"></div>
                      </div>
                    </div>
                  )}
                  {loadingTransaction === false && card.transactions === null && (
                    <h1>Вы еще не совершали транзакций</h1>
                  )}
                  {loadingTransaction === false && card.transaction !== null && card.cards.map(item => item.card) && card.transactions.toReversed().map(item =>
                    <li key={item.date}>
                      <img className='wallet-transactions-avatar' src={item.avatar_mini} alt=''/>
                      <dev className="wallet-transactions-info">
                        <p className='wallet-transactions-nick'>{item.minecraft_nick}</p>
                        <p className='wallet-transactions-description'>{item.comment}</p>
                      </dev>
                      <p className='wallet-transaction-date'>{new Date(item.date).toLocaleString()}</p>
                      <p className='wallet-transactions-money'>{item.sign} {item.amount}</p>
                    </li>        
                  )}
                </ol>       
              </div>
            </div>
          )}
          {Transf && (
            <div className='wallet-hui'>
              <div className='wallet-buttons'>
                <div className='wallet-button' onClick={toggleTransf}>Транзакции</div>
                <div className='wallet-button' onClick={toggleInvoice}>Выставить счет</div>
                <div className='wallet-button' onClick={toggleTransf}>Снять наличные</div>
              </div>
                <div>
                  <h3>Перевод Алмазов</h3>
                  <form>
                    <div className='transf_card-list'>
                      {create_transaction_data.sender_card_title === "" && (
                        <div className='wallet-card no-select none'>
                          <h3>Карта не выбрана</h3>
                        </div>
                      )}
                      {create_transaction_data.sender_card_title !== "" && (
                        <div className='wallet-card no-select'>
                          <h2>{create_transaction_data.sender_card_title}</h2>
                          <div className='wallet-card_footer'>
                            <h4>{create_transaction_data.sender_card_amount} Алмазов</h4>
                            <h3>{create_transaction_data.sender_card_id}</h3>
                          </div>
                        </div>
                      )}
                      <div className='transf_card-list_arrow'>
                        <MdKeyboardArrowRight className='icon'/>
                        {create_transaction_data.amount}
                      </div>
                      {create_transaction_data.recipient_card_title === "" && (
                        <div className='wallet-card no-select none'>
                          <h3>Карта не выбрана</h3>
                        </div>
                      )}
                      {create_transaction_data.recipient_card_title !== "" && (
                        <div className='wallet-card no-select'>
                          <h2>{create_transaction_data.recipient_card_title}</h2>
                          <div className='wallet-card_footer'>
                            <h4>? Алмазов</h4>
                            <h3>{create_transaction_data.recipient_card_id}</h3>
                          </div>
                        </div>
                      )}
                    </div>
                    <div className='transf_dropdown-list'>
                      <Dropdown lockOption={user.user.minecraft_nick} onSelect={(option) => setCreate_transaction_data(prevState => ({...prevState, sender_card_id: option.card_id, sender_card_title: option.title, sender_card_amount: card.cards.filter(item => item.card_id === option.card_id)[0].amount}))} defaultOption="Выберите вашу карту"/>
                      <Dropdown lockOption={null} onSelect={(option) => setCreate_transaction_data(prevState => ({...prevState, recipient_card_id: option.card_id, recipient_card_title: option.title}))} defaultOption="Выберите карту"/>
                    </div>
                    <div className='input-container'>
                    <input id="amount" type="number" value={create_transaction_data.amount} onChange={e => setCreate_transaction_data(prevState => ({...prevState, amount: e.target.value}))} onKeyPress={(e) => { if (e.target.value.length >= 4) { e.preventDefault(); return false; }}} placeholder=" "/>
                      <label for="amount" className="placeholder">Сумма</label>
                    </div>
                    <div className='input-container'>
                      <textarea maxLength="500" id="textarea"></textarea>
                      <label for="textarea" className="placeholder textarea" value={create_transaction_data.comment} onChange={e => setCreate_transaction_data(prevState => ({...prevState, comment: e.target.value}))}>Комментарий</label>
                    </div>
                    <div className="button" onClick={transaction_create}>Перевести</div>
                  </form>
                </div>            
            </div>
          )}
          {modalInvoice && (
            <div className='wallet-hui'>
              <div className='wallet-buttons'>
                <div className='wallet-button' onClick={toggleTransf}>Перевести</div>
                <div className='wallet-button' onClick={toggleInvoice}>Транзакции</div>
                <div className='wallet-button' onClick={toggleTransf}>Снять наличные</div>
              </div>
                <div>
                  <h3>Выставление счета</h3>
                  <form>
                    <div className='transf_card-list'>
                      <div className='wallet-card no-select'>
                        <h2>СберПанк</h2>
                        <div className='wallet-card_footer'>
                          <h4>0 Алмазов</h4>
                          <h3>00000</h3>
                        </div>
                      </div>
                      <div className='transf_card-list_arrow'>
                        <MdKeyboardArrowLeft className='icon'/>
                        {create_transaction_data.amount}
                      </div>
                      <div className='wallet-card no-select'>
                        <h2>ТиньКок </h2>
                        <div className='wallet-card_footer'>
                          <h4>? Алмазов</h4>
                          <h3>00000</h3>
                        </div>
                      </div>
                    </div>
                    <div className='transf_dropdown-list'>
                      <Dropdown lockOption={null} onSelect={handleOptionSelect} defaultOption="Выберите вашу карту"/>
                      <Dropdown lockOption={null} onSelect={handleOptionSelect} defaultOption="Выберите карту"/>
                    </div>
                    <div className='input-container'>
                    <input id="amount" type="number" value={create_card_data.card_id} onChange={e => setCreate_card_data(prevState => ({...prevState, card_id: e.target.value}))} onKeyPress={(e) => { if (e.target.value.length >= 4) { e.preventDefault(); return false; }}} placeholder=" "/>
                      <label for="amount" className="placeholder">Сумма</label>
                    </div>
                    <div className='input-container'>
                      <textarea maxLength="500" id="textarea"></textarea>
                      <label for="textarea" className="placeholder textarea">Комментарий</label>
                    </div>
                    <div className="button" onClick={transaction_create}>Выставить счет</div>
                  </form>
                </div>            
            </div>
          )}
          {modalInvoices && (
            <div className='wallet-invoices'>
              <h1>Инвойсы</h1>
              {loadingTransaction === 'loading' &&(
                <div className='wallet-loading'>
                  <div class="dot-spinner">
                    <div class="dot-spinner__dot"></div>
                    <div class="dot-spinner__dot"></div>
                    <div class="dot-spinner__dot"></div>
                    <div class="dot-spinner__dot"></div>
                    <div class="dot-spinner__dot"></div>
                    <div class="dot-spinner__dot"></div>
                    <div class="dot-spinner__dot"></div>
                    <div class="dot-spinner__dot"></div>
                  </div>
                </div>
              )}
              <ol>
              {loadingTransaction === false && card.invoices.slice().reverse().map(item =>
                <li className='wallet-invoice' key={item._id}>
                    <img className='wallet-invoice-avatar' src={item.rec_avatar} alt=""/>
                    <div className='wallet-invoice-descriptions'>
                      <p className='wallet-invoice-nick'>{item.rec}</p>
                      <p className='wallet-invoice-description'>{item.description}</p>
                    </div>
                    <p className='wallet-invoice-date'>{new Date(item.date).toLocaleDateString()}</p>
                    <p className='wallet-invoice-amount'>{item.amount} АР</p>
                    <div className='wallet-invoice-btn' onClick={() => InvoicesModal(item._id)}>Подробнее</div>
                </li>
              )}
              </ol>     
            </div>
          )}
          {Transf && false && (
          <div className='modal-Wallet'>
          <div className='modal-Wallet__content'>
            <div className='modal-Wallet__content-back' onClick={toggleTransf}>Отмена</div>
            <h2>Перевод</h2>
            <form>
              <div className='modal-Wallet__content-header'>
                <div className='input-container'>
                  <input id="card-id" type="number" value={create_card_data.card_id} onChange={e => setCreate_card_data(prevState => ({...prevState, card_id: e.target.value}))} placeholder=" "/>
                  <label for="card-id" className="placeholder">Номер карты</label>
                </div>
                <div className='input-container'>
                  <input id="amount" type="number" value={create_card_data.card_id} onChange={e => setCreate_card_data(prevState => ({...prevState, card_id: e.target.value}))} placeholder=" "/>
                  <label for="amount" className="placeholder">Сумма</label>
                </div>
              </div>
              <textarea value={create_card_data.card_id} onChange={e => setCreate_card_data(prevState => ({...prevState, card_id: e.target.value}))} placeholder="Комментарий"></textarea>
              <p>Карта</p>
              <select value={create_card_data.card_id} onChange={e => setCreate_card_data(prevState => ({...prevState, card_id: e.target.value}))}>
                <option key="1" value="" disabled >Выберите карту</option>
                {card.cards.map(item => item.card) && card.cards.map(item =>
                  <option key={item._id} value={item.card_id}>{item.title}</option>
                )}
              </select>
              <div className="auth-sumbit" onClick={transaction_create}>Перевести</div>
            </form>
          </div>
        </div>
          )}
          {modalInvoiceDescription && (card.selectedInvoices.map(item =>
            <div className='modal-wallet-invoice' key={item._id}>
              <div className='modal-wallet-invoice__content'>
                <div className='modal-wallet-invoice__content-header'>
                  <h2>Оплатить счет</h2>
                  <IoCloseCircle className='modal-wallet-invoice__content-close' onClick={toggleInvoiceDescription}/>
                </div>
                
                <div className='modal-Court__content-names'>
                  <div className='modal-wallet-invoice__content-recipient'>
                    <p>Получатель</p>
                    <h3>{item.rec}</h3>
                  </div>
                  <div className='modal-wallet-invoice__content-amount'>
                    <p>Сумма</p>
                    <h3>{item.amount}</h3> 
                  </div>
                </div>
                <div className='modal-wallet-invoice__content-details'>
                  <h3>Подробности</h3>
                  <p>{item.description}</p>
                </div>
                <select value={create_card_data.card_id} onChange={e => setCreate_card_data(prevState => ({...prevState, card_id: e.target.value}))}>
                  <option key="1" value="" disabled>Выберите карту</option>
                  {card.cards.map(item => item.card) && card.cards.map(item =>
                    <option key={item._id} value={item.card_id}>{item.title}</option>
                  )}
                </select>
                <div className='modal-wallet-invoice__content-btn'>
                  <div className='auth-sumbit' onClick={InvoiceTransactionAdd}>Оплатить</div>
                </div>
                
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  )
})



export default Wallet
