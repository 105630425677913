import React, { useLayoutEffect } from 'react'
import { IoArrowForward, IoList } from 'react-icons/io5'
import { useNavigate } from 'react-router-dom';
import { WIKI_ROUTE } from '../utils/consts';
import Header from '../components/Header';
import { BiLinkExternal } from 'react-icons/bi';
import WikiSideBar from '../components/WikiSideBar';

const WikiRules = () => {
    const navigate = useNavigate()
    const scrollToTargetElement = (targetElement) => {
        const element = document.getElementById(targetElement);
        if (element) {
            element.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
    }

    useLayoutEffect(() => {
        const targetElement = decodeURI(window.location.hash)
        const element = document.getElementById(targetElement.slice(targetElement.lastIndexOf('#') + 1));
        if (element) {
            element.scrollIntoView({ behavior: 'smooth', block: 'start' });
        } else {
            window.scrollTo(0, 0)
        }
    });

    return (
        <>
          <title>Вики ZeroShit ੶ Правила</title>
          <Header/>
          <div className='wiki'>
              <WikiSideBar/>
              <div className='wiki_content'>
                <h1 id='правила'>Правила</h1>
                <p>Не соблюдение правил приводит к бану/муту/варну/штрафу и тд. Подробно с наказаниями можно ознакомиться ниже.</p>
                <div className='wiki_content-danger'>
                    <h2 className='wiki_content-danger_indent'>При получении 3 варнов, вы будете забанены !!! <br/>Варн можно снять помогая серверу.</h2>
                </div>
                <h2 id='правила-сервера'>Правила сервера</h2>
                <div className='wiki_content-rules'>
                    <div id='правило-1'>
                        <h3>1. Читы и запрещенные модификации</h3>
                        <p>Запрещено использование читов или модификаций, которые дают преимущество над другими игроками.</p>
                        <div className='wiki_content-link wiki_content-indent' onClick={() => navigate(WIKI_ROUTE + "/dependence#mods-allow")}>
                            <BiLinkExternal className='wiki_content-link_icon'/>
                            <span>Список разрешенных модификаций</span>
                        </div>
                        <div className='wiki_content-danger'>
                            <h2>Наказание</h2>
                            <p>От варна до перманентного бана.</p>
                        </div>
                    </div>
                    <div id='правило-2' className='wiki_content-indent'>
                        <h3>2. Баги</h3>
                        <p>Запрещено использовать баги и ошибки в игре, которые дают преимущество над другими игроками.</p>
                        <div className='wiki_content-note wiki_content-indent'>
                            <h2>Примечание!</h2>
                            <p>Разрешено использование Suppressor и всех его видов, за исключением использования его для дюпа.</p>
                        </div>
                        <div className='wiki_content-danger'>
                            <h2>Наказание</h2>
                            <p>От варна до перманентного бана.</p>
                        </div>
                    </div>
                    <div id='правило-3' className='wiki_content-indent'>
                        <h3>3. Взлом и попытка взлома аккаунта</h3>
                        <p>Запрещено взламывать или пытаться взломать аккаунт другого игрока.</p>
                        <div className='wiki_content-danger'>
                            <h2>Наказание</h2>
                            <p>Перманентный бан.</p>
                        </div>
                    </div>
                    <div id='правило-4' className='wiki_content-indent'>
                        <h3>4. Гриферство</h3>
                        <p>Гриферство - акт нанесения материального ущерба игрокам.</p>
                        <br/>
                        <p>Запрещено гриферство и уничтожение имущества других игроков без их согласия.</p>
                        <div className='wiki_content-danger'>
                            <h2>Наказание</h2>
                            <p>Штраф + варн.<br/>При гриферстве более 10 чанков, вы будете перманентно забанены.</p>
                        </div>
                    </div>
                    <div id='правило-5'>
                        <h3>5. Убийство игроков</h3>
                        <p>Запрещено убийство других игроков без их согласия.</p>
                        <div className='wiki_content-danger'>
                            <h2>Наказание</h2>
                            <p>Штраф + варн.<br/>При массовом убийстве вы будете забанены.</p>
                        </div>
                    </div>
                    <div id='правило-6'>
                        <h3>6. Неадекватное поведение</h3>
                        <p>Запрещено неадекватное поведение, которое может помешать другим игрокам наслаждаться игрой.</p>
                        <br/>
                        <p>Если Игрок стримит на сервере запрещено использовать слова и строить постройки, запрещенные на платформе Twitch.</p>
                        <div className='wiki_content-danger'>
                            <h2>Наказание</h2>
                            <p>От штрафа до варна.</p>
                        </div>
                    </div>
                </div>
                <div className='wiki_content-next' onClick={() => navigate(WIKI_ROUTE + "/rules/court")}>
                  <div>
                    <p>Далее</p>
                    <h3>Правила подачи иска</h3>
                  </div>
                  <IoArrowForward className='wiki_content-next_arrow'/>
                </div>
              </div>
              <div className='wiki_on_page'>
                <div className='wiki_on_page-sticky'>
                  <h3>
                    <IoList className='wiki_on_page-sticky_list'/>
                    На этой странице
                  </h3>
                  <div className='wiki_on_page-sticky_navigation'>
                    <div>
                      <span onClick={() => scrollToTargetElement('правила-сервера')}>Правила сервера</span>
                      <p onClick={() => scrollToTargetElement('правило-1')}><span>Читы и запрещенные модификации</span></p>
                      <p onClick={() => scrollToTargetElement('правило-2')}><span>Баги</span></p>
                      <p onClick={() => scrollToTargetElement('правило-3')}><span>Взлом и попытка взлома аккаунта</span></p>
                      <p onClick={() => scrollToTargetElement('правило-4')}><span>Гриферство</span></p>
                      <p onClick={() => scrollToTargetElement('правило-5')}><span>Убийство игроков</span></p>
                      <p onClick={() => scrollToTargetElement('правило-6')}><span>Неадекватное поведение</span></p>
                    </div>
                  </div>
                </div>
              </div>
          </div>
        </>
      )
}

export default WikiRules