import React from 'react'
import { WIKI_ROUTE } from '../utils/consts'
import { useLocation, useNavigate } from 'react-router-dom';
import { MdOutlineKeyboardArrowDown, MdOutlineKeyboardArrowRight } from 'react-icons/md';

const WikiSideBar = () => {
    const location = useLocation();
    const navigate = useNavigate()

    return (
        <div className='wiki_sidebar'>
            <div className='wiki_sidebar-sticky'>
                <div className={(location.pathname === WIKI_ROUTE ? 'wiki_sidebar-sticky_active' : '')} onClick={() => navigate(WIKI_ROUTE)}>Введение</div>
                <div className={(location.pathname === WIKI_ROUTE + "/start" ? 'wiki_sidebar-sticky_active' : '')} onClick={() => navigate(WIKI_ROUTE + "/start")}>Начало</div>
                <div className={(location.pathname === WIKI_ROUTE + "/rules" ? 'wiki_sidebar-sticky_active' : '')} onClick={() => navigate(WIKI_ROUTE + "/rules")}>Правила {(location.pathname === WIKI_ROUTE + "/rules" || location.pathname === WIKI_ROUTE + "/rules/court" ? <MdOutlineKeyboardArrowDown className='wiki_sidebar-stick_arrow'/> : <MdOutlineKeyboardArrowRight className='wiki_sidebar-stick_arrow'/>)}</div>
                {(
                    location.pathname === WIKI_ROUTE + "/rules" || location.pathname === WIKI_ROUTE + "/rules/court" ?
                    <div className='wiki_sidebar-sticky_list'>
                        <div className={(location.pathname === WIKI_ROUTE + "/rules/court" ? 'wiki_sidebar-sticky_active' : '')} onClick={() => navigate(WIKI_ROUTE + "/rules/court")}>Правила подачи иска</div>
                    </div>
                    : ''
                )}
                <div className={(location.pathname === WIKI_ROUTE + "/dependence" ? 'wiki_sidebar-sticky_active' : '')} onClick={() => navigate(WIKI_ROUTE + "/dependence")}>Моды и ресурспаки</div>
                <div className={(location.pathname === WIKI_ROUTE + "/mechanics" ? 'wiki_sidebar-sticky_active' : '')} onClick={() => navigate(WIKI_ROUTE + "/mechanics")}>Механики и команды {(location.pathname === WIKI_ROUTE + "/mechanics" ? <MdOutlineKeyboardArrowDown className='wiki_sidebar-stick_arrow'/> : <MdOutlineKeyboardArrowRight className='wiki_sidebar-stick_arrow'/>)}</div>
                {(
                    location.pathname === WIKI_ROUTE + "/mechanics" ?
                    <div className='wiki_sidebar-sticky_list'>
                        <div className={(location.pathname === WIKI_ROUTE + "/mechanics/crafts" ? 'wiki_sidebar-sticky_active' : '')} onClick={() => navigate(WIKI_ROUTE + "/mechanics/crafts")}>Новые крафты</div>
                        <div className={(location.pathname === WIKI_ROUTE + "/mechanics/commands" ? 'wiki_sidebar-sticky_active' : '')} onClick={() => navigate(WIKI_ROUTE + "/mechanics/commands")}>Команды</div>
                    </div>
                    : ''
                )}
                <div className={(location.pathname === WIKI_ROUTE + "/guides" ? 'wiki_sidebar-sticky_active' : '')} onClick={() => navigate(WIKI_ROUTE + "/guides")}>Гайды {(location.pathname === WIKI_ROUTE + "/guides" ? <MdOutlineKeyboardArrowDown className='wiki_sidebar-stick_arrow'/> : <MdOutlineKeyboardArrowRight className='wiki_sidebar-stick_arrow'/>)}</div>
                {(
                    location.pathname === WIKI_ROUTE + "/guides" ?
                    <div className='wiki_sidebar-sticky_list'>
                        <div className={(location.pathname === WIKI_ROUTE + "/guides" ? 'wiki_sidebar-sticky_active' : '')} onClick={() => navigate(WIKI_ROUTE + "/rules/court")}>Гайды 1</div>
                    </div>
                    : ''
                )}
            </div>
        </div>
    )
}

export default WikiSideBar