import React, { useEffect, useState } from 'react'
import { authentication, authorization } from '../http/userAPI'
import { useNavigate } from 'react-router-dom'
import { GUEST_ROUTE, PROFILE_ROUTE } from '../utils/consts'

const Authorization = () => {
  var url = new URL(document.location)
  const code = url.searchParams.get('code')
  const [data, setData] = useState({})
  const [loading, setLoading] = useState(true)
  var timeout = 2000
  const navigate = useNavigate()

  useEffect(() => {
    console.log(data)
    if (code === '' || code === "null") {
      setData({"error": "Дискорд токен не найден"})
      authentication().then(data => {
        if (data !== null) {
          setData({"message": "Вы уже авторизованы!"})
          setTimeout(() => {
            navigate(PROFILE_ROUTE)
          }, timeout)
        }
      }).finally(() => setLoading(false))
    } else {
      authentication().then(data => {
      if (data !== null) {
        if (data.data.authorization === "true") {
          setData({"message": "Вы уже авторизованы!"})
          setTimeout(() => {
            navigate(PROFILE_ROUTE)
          }, timeout)
        } 
      }
    }).finally(() => setLoading(false))
      authorization(code).then(data => {
        console.log(data)
        if (data !== null) {
          if (data.hasOwnProperty('error')) {
            setData({"error": data.error})
          } else if (data.hasOwnProperty('message')) {
            console.log(1)
            setData(data.message)
            setTimeout(() => {
              navigate(GUEST_ROUTE)
            }, timeout * 2)
          } else {
            console.log(2)
            setData({"message": "Вы успешно авторизоровались!"})
            setTimeout(() => {
              window.location.href = PROFILE_ROUTE
            }, timeout)
          }
        }
      }).finally(() => setLoading(false))
    }
  // eslint-disable-next-line  
  }, [])
  return (
    <div className='authorization'>
      <title>ZeroShit ੶ Авторизация</title>
      <h1>Авторизация</h1>
      <h2>{(data.hasOwnProperty('message') && loading === false ? "Переадресация . . ." : (data.hasOwnProperty('error') && loading === false ?
       "" 
       : "Пожалуйста не закрывайте страницу!"))}</h2>
      {data.hasOwnProperty('error') && loading === false && (
        <div className='authorization-error'>
          <h3>Ошибка:</h3>
          <p>{data.error}</p>
          <h2>Попробуйте авторизоваться заного</h2>
          <div onClick={() => window.location.replace("https://discord.com/oauth2/authorize?client_id=1206144488750190653&response_type=code&redirect_uri=https%3A%2F%2Fzerosquad.fun%2Fauthorization&scope=identify+guilds+guilds.join")} className='header_login'>Авторизоваться</div>
        </div>
      )}
      {data.hasOwnProperty('message') && loading === false && (
        <div className='authorization-error'>
          <h3>Успешно:</h3>
          <p>{data.message}</p>
        </div>
      )}

      <div class="box-of-star1">
        <div class="star star-position1"></div>
        <div class="star star-position2"></div>
        <div class="star star-position3"></div>
        <div class="star star-position4"></div>
        <div class="star star-position5"></div>
        <div class="star star-position6"></div>
        <div class="star star-position7"></div>
      </div>
      <div class="box-of-star2">
        <div class="star star-position1"></div>
        <div class="star star-position2"></div>
        <div class="star star-position3"></div>
        <div class="star star-position4"></div>
        <div class="star star-position5"></div>
        <div class="star star-position6"></div>
        <div class="star star-position7"></div>
      </div>
      <div class="box-of-star3">
        <div class="star star-position1"></div>
        <div class="star star-position2"></div>
        <div class="star star-position3"></div>
        <div class="star star-position4"></div>
        <div class="star star-position5"></div>
        <div class="star star-position6"></div>
        <div class="star star-position7"></div>
      </div>
      <div class="box-of-star4">
        <div class="star star-position1"></div>
        <div class="star star-position2"></div>
        <div class="star star-position3"></div>
        <div class="star star-position4"></div>
        <div class="star star-position5"></div>
        <div class="star star-position6"></div>
        <div class="star star-position7"></div>
      </div>
      <div data-js="astro" class="astronaut">
        <div class="head"></div>
        <div class="arm arm-left"></div>
        <div class="arm arm-right"></div>
        <div class="body">
          <div class="panel"></div>
        </div>
        <div class="leg leg-left"></div>
        <div class="leg leg-right"></div>
        <div class="schoolbag"></div>
      </div>
    </div>
  )
}

export default Authorization