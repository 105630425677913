import Court from "./pages/Court"
import Main from "./pages/Main"
import Profile from "./pages/Profile"
import Users from "./pages/Users"
import Wallet from "./pages/Wallet"
import User from "./pages/User"
import { COURT_ROUTE, MAIN_ROUTE, PROFILE_ROUTE, WALLET_ROUTE, USERS_ROUTE, ADMIN_ROUTE, SHOP_ROUTE, SETTINGS_ROUTE, DONATE_ROUTE, DOGOVOR_ROUTE, CONTACTS_ROUTE, WIKI_ROUTE, MAP_ROUTE, STATISTIC_ROUTE, AUTHORIZATION_ROUTE, AUTH_MAP_ROUTE, GUEST_ROUTE, OAUTH_ROUTE, FRESH_ROUTE } from "./utils/consts"
import Admin from "./pages/Admin"
import Shop from "./pages/Shop"
import Settings from "./pages/Settings"
import Donate from "./pages/Donate"
import Dogovor from "./pages/Dogovor"
import Contacts from "./pages/Contacts"
import CourtPage from "./pages/CourtPage"
import Wiki from "./pages/Wiki"
import Map from "./pages/Map"
import WikiStart from "./pages/WikiStart"
import WikiRules from "./pages/WikiRules"
import WikiRulesCourt from "./pages/WikiRulesCourt"
import WIkiDependence from "./pages/WIkiDependence"
import WikiMechanics from "./pages/WikiMechanics"
import WikiGuides from "./pages/WikiGuides"
import Statistic from "./pages/Statistic"
import Authorization from "./pages/Authorization"
import AuthMap from "./pages/AuthMap"
import Guest from "./pages/Guest"
import Oauth from "./pages/Oauth"
import Fresh from "./pages/Fresh"

export const authRoutes = [
    {
        path: PROFILE_ROUTE,
        Component: <Profile/>
    },
    {
        path: ADMIN_ROUTE,
        Component: <Admin/>
    },
    {
        path: COURT_ROUTE,
        Component: <Court/>
    },
    {
        path: COURT_ROUTE + '/:id',
        Component: <CourtPage/>
    },
    {
        path: WALLET_ROUTE,
        Component: <Wallet/>
    },
    {
        path: USERS_ROUTE,
        Component: <Users/>
    },
    {
        path: USERS_ROUTE + '/:id',
        Component: <User/>
    },
    {
        path: AUTH_MAP_ROUTE,
        Component: <AuthMap/>
    },
    {
        path: SHOP_ROUTE,
        Component: <Shop/>
    },
    {
        path: SETTINGS_ROUTE,
        Component: <Settings/>
    },
    {
        path: DONATE_ROUTE,
        Component: <Donate/>
    }
]

export const noAuthRoutes = [

    {
        path: GUEST_ROUTE,
        Component: <Guest/>
    }

]

export const publicRoutes = [

    {
        path: MAIN_ROUTE,
        Component: <Main/>
    },
    {
        path: DOGOVOR_ROUTE,
        Component: <Dogovor/>
    },
    {
        path: CONTACTS_ROUTE,
        Component: <Contacts/>
    },
    {
        path: STATISTIC_ROUTE,
        Component: <Statistic/>
    },
    {
        path: MAP_ROUTE,
        Component: <Map/>
    },
    {
        path: AUTHORIZATION_ROUTE,
        Component: <Authorization/>
    },
    {
        path: WIKI_ROUTE,
        Component: <Wiki/>
    },
    {
        path: WIKI_ROUTE + "/start",
        Component: <WikiStart/>
    },
    {
        path: WIKI_ROUTE + "/rules",
        Component: <WikiRules/>
    },
    {
        path: WIKI_ROUTE + "/rules/court",
        Component: <WikiRulesCourt/>
    },
    {
        path: WIKI_ROUTE + "/dependence",
        Component: <WIkiDependence/>
    },
    {
        path: WIKI_ROUTE + "/mechanics",
        Component: <WikiMechanics/>
    },
    {
        path: WIKI_ROUTE + "/guides",
        Component: <WikiGuides/>
    },
    {
        path: OAUTH_ROUTE,
        Component: <Oauth/>
    },
    {
        path: FRESH_ROUTE,
        Component: <Fresh/>
    }

]