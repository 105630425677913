import {makeAutoObservable} from "mobx";

export default class UserStore {
    constructor() {
        this._isAuth = false
        this._user = []
        this._users = []
        this._anotherUser = []
        this._adminState = []

        this._heIsAdmin = false
        this._heIsMayor = false
        this._heIsRefere = false
        this._heIsAuth = false
        makeAutoObservable(this)
    }

    setIsAuth(bool) {
        this._isAuth = bool
    }
    setUser(user) {
        this._user = user
    }
    setUsers(users) {
        this._users = users
    }
    setAnotherUser(user) {
        this._anotherUser = user
    }
    setAdminState(adminState) {
        this._adminState = adminState
    }

    setHeIsAdmin(bool) {
        this._heIsAdmin = bool
    }
    setHeIsMayor(bool) {
        this._heIsMayor = bool
    }
    setHeIsReferee(bool) {
        this._heIsRefere = bool
    }
    setHeIsAuth(bool) {
        this._heIsAuth = bool
    }

    get isAuth() {
        return this._isAuth
    }
    get user() {
        return this._user
    }
    get users() {
        return this._users
    }
    get anotherUser() {
        return this._anotherUser
    }
    get adminState() {
        return this._adminState
    }

    get heIsAdmin() {
        return this._heIsAdmin
    }
    get heIsMayor() {
        return this._heIsMayor
    }
    get heIsReferee() {
        return this._heIsRefere
    }
    get heIsAuth() {
        return this._heIsAuth
    }
}