import React, { useContext, useEffect, useState } from 'react'
import { Context } from '../index'
import HeaderIsAuth from '../components/HeaderIsAuth'
import { observer } from 'mobx-react-lite'
import { FaDiscord, FaSpinner } from 'react-icons/fa'
import { checkCardUser } from '../http/cardAPI'
import { useParams } from 'react-router-dom'
import { checkUser } from '../http/userAPI'
import { IoHeart } from 'react-icons/io5';

const Profile = observer(() => {
  const {user, card} = useContext(Context)
  const [loading, setLoading] = useState(true)
  const {id} = useParams()

  useEffect(() => {
    checkCardUser(id).then(data => {
      if (data == null){
        card.setIsCard(true)
      } else {
        card.setIsCard(false)
        card.setCards(data)
      }
    })
    checkUser(id).then(data => {
        user.setAnotherUser(data)
    }).finally(() => setLoading(false))
  // eslint-disable-next-line
  }, [])

  if (loading) {
    return (
      <>
        <HeaderIsAuth/>
        <div className='loading'>
        <FaSpinner/> 
        </div>
      </>
    )
  }

  return (
    <>
      <HeaderIsAuth/>
      <div className='profile'>
        <div className='profile_header'>
          <div className='profile_cover'></div>
          <div className='profile_header-footer'>
            <img className="profile_avatar" src={user.anotherUser.avatar} alt=""/>
            <h2 className='profile_header-name'>{user.anotherUser.nick}</h2>
          </div>
        </div>
        <div className='profile_information'>
          <div className='profile_information-blocks'>
            <div className='profile_information-list'>
              {user.anotherUser.description !== "" && user.anotherUser.family !== "" && (
                <div className='profile_information-list_inf'>
                  <h1>Краткая информация</h1>
                  {user.anotherUser.description !== "" && (
                    <div className='profile_information-list_inf-description'>
                      <h3>{user.anotherUser.description}</h3>
                    </div>
                  )}
                  {user.anotherUser.family !== "" && (
                    <div className='profile_information-list_inf-family'>
                      <IoHeart className='profile_information-list_inf-family_icon'/>
                      <h3>{user.anotherUser.family}</h3>
                    </div>
                  )}
                  
                </div>
              )}
              <div className='profile_information-list_social'>
                <h1>Соц сети</h1>
                <div className='profile_information-list_social-discord'>
                  <FaDiscord className='profile_information-list_social-discord_icon'/>
                  <h3>{user.anotherUser.discord}</h3>
                </div>
              </div>
              <div className='profile_information-list_cards'>
                <h1>Карты</h1>
              </div>
            </div>
            <div className='profile_information-blog'>
              
            </div>
          </div>
        </div>
      </div> 
    </>
  )
})

export default Profile