import {makeAutoObservable} from "mobx";

export default class CardStore {
    constructor() {
        this._isCard = false
        this._isRec = false
        this._isSender = false
        this._isSenderRec = false
        this._cards = []
        this._transactions = []
        this._cityTransactions = []
        this._selectedCard = []
        this._cityCard = []
        this._invoices = []
        this._selectedInvoices = []
        makeAutoObservable(this)
    }

    setIsCard(bool) {
        this._isCard = bool
    }
    setIsRec(bool) {
        this._isRec = bool
    }
    setIsSender(bool) {
        this._isSender = bool
    }
    setIsSenderRec(bool) {
        this._isSenderRec = bool
    }
    setCards(cards) {
        this._cards = cards
    }
    setCityCard(cityCard) {
        this._cityCard = cityCard
    }
    setTransactions(transactions) {
        this._transactions = transactions
    }
    setCityTransactions(cityTransactions) {
        this._cityTransactions = cityTransactions
    }
    setIsSelectedCard(card) {
        this._selectedCard = card
    }
    setInvoices(invoices) {
        this._invoices = invoices
    }
    setSelectedInvoices(selectedInvoices) {
        this._selectedInvoices = selectedInvoices
    }


    get isCard() {
        return this._isCard
    }
    get isRec() {
        return this._isRec
    }
    get isSender() {
        return this._isSender
    }
    get isSenderRec() {
        return this._isSenderRec
    }
    get cards() {
        return this._cards
    }
    get cityCard() {
        return this._cityCard
    }
    get transactions() {
        return this._transactions
    }
    get cityTransactions() {
        return this._cityTransactions
    }
    get IsSelectedCard() {
        return this._selectedCard
    }
    get invoices() {
        return this._invoices
    }
    get selectedInvoices() {
        return this._selectedInvoices
    }
}
