import React, { useEffect, useState } from 'react'
import Header from '../components/Header'
import { IoTime } from 'react-icons/io5'
import { users } from '../http/userAPI'
import StatisticDiagram from '../components/StatisticDiagram'

const Statistic = () => {
    const [loadingPlayers, setLoadingPlayers] = useState(true)
    const [data, setData] = useState([])
    const [offset, setOffset] = useState(0)
    const limit = 25

    const handleScroll = () => {
        if (window.innerHeight + document.documentElement.scrollTop >= document.documentElement.offsetHeight) {
          loadMorePlayers()
        }
    }

    const loadMorePlayers = () => {
        setOffset((prevOffset) => prevOffset + limit)
    }

    useEffect(() => {
        window.addEventListener('scroll', handleScroll)
        return () => {
          window.removeEventListener('scroll', handleScroll)
        };
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        users(offset, limit).then((newData) => {
            const hasDuplicates = newData.some((newItem) =>
              data.some((existingItem) => newItem.minecraft_nick === existingItem.minecraft_nick)
            )
            if (!hasDuplicates) {
              setData((prevData) => [...prevData, ...newData])
              setLoadingPlayers(false)
            }
          })
          // eslint-disable-next-line
    }, [offset])

    return (
        <>
            <title>MineShit ੶ Статистика</title>
            <Header/>
            <StatisticDiagram/>
            <div className='players'>
                <div></div>
                <div className='players_list'>
                    {loadingPlayers === true ? (
                    <>
                        <div className='user no-select background_user-no-select' key={0}>
                            <div>
                                <div className='user-avatar loading-animation'/>
                                <h2 className='loading-animation'>.</h2>
                            </div>
                            <p className='user-description'></p>
                            <div className='users-time'>
                                <IoTime className='users-time__icon'/>
                                <p>0 ч</p>
                            </div>
                        </div>
                    </>
                    ) : ( 
                    data.map(item => 
                        <div className='user no-select background_user-no-select' key={item._id}>
                            <div>
                                <img className='user-avatar loading-animation' src={item.avatar} alt=''/>
                                <h2>{item.minecraft_nick}</h2>
                            </div>
                            <p className='user-description'>{item.description}</p>
                            <div className='users-time'>
                                <IoTime className='users-time__icon'/>
                                <p>{item.time} ч</p>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </>
    )
}

export default Statistic