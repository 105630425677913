import React, { useLayoutEffect } from 'react'
import { IoArrowForward, IoList } from 'react-icons/io5'
import { useNavigate } from 'react-router-dom';
import { WIKI_ROUTE } from '../utils/consts';
import Header from '../components/Header';
import { BiLinkExternal } from 'react-icons/bi'

import DiscordPng from '../img/wiki_start_1.png'
import Discord2Png from '../img/wiki_start_2.png'
import Discord3Png from '../img/wiki_start_3.png'
import Discord4Png from '../img/wiki_start_4.png'
import WikiSideBar from '../components/WikiSideBar';

const WikiStart = () => {
    const navigate = useNavigate()
    const scrollToTargetElement = (targetElement) => {
        const element = document.getElementById(targetElement);
        if (element) {
            element.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
    }

    useLayoutEffect(() => {
        const targetElement = decodeURI(window.location.hash)
        const element = document.getElementById(targetElement.slice(targetElement.lastIndexOf('#') + 1));
        console.log(targetElement)
        if (element) {
            element.scrollIntoView({ behavior: 'smooth', block: 'start' });
        } else {
            window.scrollTo(0, 0)
        }
    });

    return (
        <>
          <title>Вики ZeroShit ੶ Начало</title>
          <Header/>
          <div className='wiki'>
              <WikiSideBar/>
              <div className='wiki_content'>
                <h1 id='начало'>Начало</h1>
                <p>MineShit - это ванильный сервер Minecraft. Здесь вы найдете дружелюбное сообщество и огромное количество контента. Общайтесь с другими игроками, используя голосовой чат, и выразите свои эмоции при помощи EmoteCraft'a, чтобы оторваться на нереальной тусовке.</p>
                <h2 id='как-попасть-на-сервер'>Как попасть на сервер?</h2>
                <div>
                    <h3>1. Авторизоваться через дискорд</h3>
                    <div className='wiki_content-link' onClick={() => window.location.replace(process.env.REACT_APP_API_URL + "login")}>
                        <BiLinkExternal className='wiki_content-link_icon'/>
                        <span>Авторизоваться</span>
                    </div>
                    <p>Нажимаем кнопку "Авторизовать".</p>
                    <img src={DiscordPng} alt=''/>
                    <p>Затем переходим в Discord и ищем дискорд MineShit в списке серверов.</p>
                </div>
                <div>
                    <h3>2. Заполнить форму в дискорд сервере</h3>
                    <p>Нажимаем кнопку "Подать заявку на сервер"</p>
                    <img src={Discord2Png} alt=''/>
                    <p className='wiki_content-indent'>Заполняем форму</p>
                    <div className='wiki_content-warning'>
                        <h2>Внимание!</h2>
                        <p>Пожалуйста, предоставьте более подробную информацию в поле "Откуда вы узнали про сервер". Если узнали от друга, укажите его ник!</p>
                    </div>
                    <h4>Пример:</h4>
                    <img src={Discord3Png} alt=''/>
                    <p className='wiki_content-indent'>Теперь ожидаем ответ от администрации. Обычно ответ приходит в течение 12 часов. Как только получим сообщение от бота, сможем зайти на сервер!</p>
                    <div className='wiki_content-note'>
                        <h2>Примечание!</h2>
                        <p>Если у вас не установлена галочка в разделе Настройки &gt; Конфиденциальность, бот не сможет отправить вам сообщение, однако вас все равно примут на сервер.</p>
                        <img src={Discord4Png} alt=''/>
                    </div>
                </div>
                <div className='wiki_content-next' onClick={() => navigate(WIKI_ROUTE + "/rules")}>
                  <div>
                    <p>Далее</p>
                    <h3>Правила</h3>
                  </div>
                  <IoArrowForward className='wiki_content-next_arrow'/>
                </div>
              </div>
              <div className='wiki_on_page'>
                <div className='wiki_on_page-sticky'>
                  <h3>
                    <IoList className='wiki_on_page-sticky_list'/>
                    На этой странице
                  </h3>
                  <div className='wiki_on_page-sticky_navigation'>
                    <div>
                      <span onClick={() => scrollToTargetElement('начало')}>Начало</span>
                      <p onClick={() => scrollToTargetElement('как-попасть-на-сервер')}><span>Как попасть на сервер</span></p>
                    </div>
                  </div>
                </div>
              </div>
          </div>
        </>
      )
}

export default WikiStart