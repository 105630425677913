import React from 'react'
import Header from '../components/Header'

const Dogovor = () => {
  return (
    <div>
      <title>MineShit ੶ Договор-оферта</title>
      <Header/>
      <div className='Dogovor'>
        <h1>Договор-оферта</h1>
        <ol>
          <li>
            <h2>1. Общие положения</h2>
            <h3>
              1.1 Настоящий Договор является официальным предложением (публичной офертой) ZeroShit (в дальнейшем «Исполнитель») для
              полностью дееспособного физического (далее – «Заказчик»), которое примет настоящее
              предложение, на указанных ниже условиях.
            </h3>
            <h3>
              1.2. В соответствии с пунктом 2 статьи 437 Гражданского Кодекса Российской Федерации (ГК РФ),
              в случае принятия изложенных ниже условий и оплаты услуг юридическое или физическое лицо,
              производящее акцепт этой оферты, становится Заказчиком (в соответствии с пунктом 3 статьи 438
              ГК РФ акцепт оферты равносилен заключению Договора на условиях, изложенных в оферте).
            </h3>
            <h3>
              1.3. Моментом полного и безоговорочного принятия Заказчиком предложения Исполнителя
              заключить Договор оферты (акцептом оферты) считается факт подтверждения готовности
              совершить оплату услуги Исполнителя, посредством нажатия кнопки «Оплатить» на сайте
              <a href='https://zerosquad.fun'> https://zerosquad.fun/</a>. Текст настоящего Договора-оферты (далее по тексту – «Договор»)
              расположен по адресу: <a href='https://zerosquad.fun'>https://zerosquad.fun/</a>.
            </h3>
            <h3>
              1.4. Осуществляя акцепт Договора в порядке, определенном п. 1.3 Договора, Заказчик
              подтверждает, что он ознакомлен, согласен, полностью и безоговорочно принимает все условия
              Договора в том виде, в каком они изложены в тексте Договора, в том числе в приложениях к
              Договору, являющихся его неотъемлемой частью.
            </h3>
            <h3>
              1.5. Клиент согласен, что акцепт Договора в порядке, указанном в п. 1.2 Договора является
              заключением Договора на условиях, изложенных в нем.
            </h3>
            <h3>
              1.6. Договор не может быть отозван.
            </h3>
            <h3>
              1.7. Договор не требует скрепления печатями и/или подписания Заказчиком и Исполнителем
              (далее по тексту - Стороны) и сохраняет при этом юридическую силу.
            </h3>
            <h3>
              1.8 Администрация сохраняет за собой право отменить регистрацию, временно или постоянно заблокировать учетную запись Заказчика в одностороннем порядке.
            </h3>
          </li>
          <li>
            <h2>2. Предмет договора</h2>
            <h3>
              2.1. Предметом настоящего Договора является возмездное оказание Исполнителем
              образовательных услуг в соответствии с условиями настоящего Договора.
            </h3>
            <h3>
              2.2. Заказчик полностью принимает условия Договора и оплачивает услуги Исполнителя в
              соответствии с условиями настоящего Договора.
            </h3>
          </li>
          <li>
            <h2>3. Оплата Услуг</h2>
            <h3>
              3.1. Стоимость услуг по Договору определяется в соответствии с действующими ценами и
              прописана на сайте <a href='https://zerosquad.fun'> https://zerosquad.fun/</a>
            </h3>
            <h3>
              3.2. Стоимость услуги может быть изменена Исполнителем в одностороннем порядке. 
            </h3>
            <h3>
              3.3. Способы оплаты услуги указаны при оформлении платежа.
            </h3>
          </li>
          <li>
            <h2>4. Интеллектуальная собственность</h2>
            <h3>
              4.1. Вся текстовая информация и графические изображения, находящиеся на сайте
              <a href='https://zerosquad.fun'> https://zerosquad.fun/</a> являются собственностью Исполнителя.
            </h3>
          </li>
          <li>
            <h2>5. Особые условия и ответственность сторон. </h2>
            <h3>
              5.1. Исполнитель несет ответственность за своевременность предоставляемых услуг при
              выполнении Заказчиком установленных требований и правил, размещенных на сайте
              <a href='https://zerosquad.fun'> https://zerosquad.fun/</a>.
            </h3>
            <h3>
              5.2. Исполнитель освобождается от ответственности за нарушение условий Договора, если такое
              нарушение вызвано действием обстоятельств непреодолимой силы (форс-мажор), включая:
              действия органов государственной власти, пожар, наводнение, землетрясение, другие стихийные
              действия, отсутствие электроэнергии, забастовки, гражданские волнения, беспорядки, любые иные
              обстоятельства, не ограничиваясь перечисленным, которые могут повлиять на выполнение
              Исполнителем Договора.
            </h3>
            <h3>
              5.3. Исполнитель не несет ответственности за качество каналов связи общего пользования или
              служб, предоставляющих доступ Заказчика к его услугам.
            </h3>
          </li>
          <li>
            <h2>6. Конфиденциальность и защита персональной информации</h2>
            <h3>
              6.1. Исполнитель обязуется не разглашать полученную от Заказчика информацию.
            </h3>
            <h3>
              6.2. Не считается нарушением обязательств разглашение информации в соответствии с
              обоснованными и применимыми требованиями закона.
            </h3>
            <h3>
              6.3. Исполнитель получает информацию об IP-адресе посетителя Сайта
              <a href='https://zerosquad.fun'> https://zerosquad.fun/</a>. Данная информация не используется для установления личности
              посетителя
            </h3>
            <h3>
              6.4. Исполнитель не несет ответственности за сведения, предоставленные Заказчиком на сайте
              <a href='https://zerosquad.fun'> https://zerosquad.fun/</a> в общедоступной форме.
            </h3>
          </li>
          <li>
            <h2>7. Порядок рассмотрения претензий и споров</h2>
            <h3>
              7.1. Претензии Заказчика по предоставляемым услугам принимаются Исполнителем к
              рассмотрению по электронной почте в течение 7 (рабочих) дней с момента возникновения спорной
              ситуации.
            </h3>
            <h3>
              7.2. При рассмотрении спорных ситуаций Исполнитель вправе запросить у Заказчика всю
              интересующую документацию относительно рассматриваемого мероприятия. В случае
              непредоставления Заказчиком документов в течение 1 рабочего дня после дня требования,
              претензия рассмотрению Исполнителем не подлежит.
            </h3>
            <h3>
              7.3. Исполнитель и Заказчик, принимая во внимания характер оказываемой услуги, обязуются в
              случае возникновения споров и разногласий, связанных с оказанием услуг, применять досудебный
              порядок урегулирования спора. В случае невозможности урегулирования спора в досудебном
              порядке стороны вправе обратиться в суд.
            </h3>
          </li>
          <li>
            <h2>8. Прочие условия</h2>
            <h3>
              8.1. Заказчик обладает всеми правами и полномочиями, необходимыми для заключения и
              исполнения Договора.
            </h3>
            <h3>
              8.2. Заказчик вправе в любое время в одностороннем порядке отказаться от услуг Исполнителя. В
              случае одностороннего отказа Заказчика от услуг Исполнителя произведенная оплата не
              возвращается, но может быть перенесена на другую услугу.
            </h3>
            <h3>
              8.3. Исполнитель оставляет за собой право изменять или дополнять любые из условий настоящего
              Договора в любое время, опубликовывая все изменения на своем сайте.
            </h3>
            <h3>
              8.4. По всем вопросам, не урегулированным настоящим Договором, стороны руководствуются
              действующим законодательством Российской Федерации.
            </h3>
            <h3>
              8.5. Признание судом недействительности какого-либо положения настоящего Договора и правил
              не влечет за собой недействительность остальных положений.
            </h3>
          </li>
          <li>
            <h2>9. Контактные данные</h2>
            <h3>Дискорд: lalolog_</h3>
          </li>
        </ol>
      </div>
    </div>
  )
}

export default Dogovor