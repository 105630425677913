import { $authHost } from "./index";

export const create_card = async (card) => {
    try {
        const { data } = await $authHost.post('/api/cards/create',  card );
        return data;
    } catch (error) {
        if (error.response) {
            console.error(error.response.status + " " + error.response.data.detail);
            return error.response.data.detail
        }
        console.error(error);
        return { "error": "Ошибка обратитесь к администрации" };
    }
}

export const get_cards = async () => {
    try {
        const {data} = await $authHost.get('/api/cards/all')
        return data
    } catch (error) {
        if (error.response) {
            console.error(error.response.status + " " + error.response.data.detail);
        }
        console.error(error);
        return { "error": "Ошибка обратитесь к администрации" };
    }
    
}

export const checkCardUser = async (id) => {
    const {data} = await $authHost.get('/checkCard/' + id)
    return data
}

export const create_transaction = async (transaction) => {
    const {data} = await $authHost.post('/api/transactions/create', transaction)
    return data
}

export const get_transactions = async (card_id) => {
    try {
        const {data} = await $authHost.get(`/api/transactions/?card_id=${card_id}`)
        return data
    } catch (error) {
        if (error.response) {
            console.error(error.response.status + " " + error.response.data.detail);
            return {"error": error.response.data.detail}
        }
        console.error(error);
        return { "error": "Ошибка обратитесь к администрации" };
    }
}

export const addInvoice = async (invoiceDescription, invoiceTo, invoiceFrom, invoiceAmount) => {
    const {data} = await $authHost.post('/addInvoice', {invoiceDescription, invoiceTo, invoiceFrom, invoiceAmount})
    return data
}

export const checkInvoice = async () => {
    const {data} = await $authHost.get('/checkInvoice')
    return data
}

export const deleteInvoice = async (id) => {
    const {data} = await $authHost.post('/deleteInvoice', {id})
    return data
}