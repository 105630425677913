import { $authHost } from "./index";

export const addShop = async (name, description, price, kit, icon, number, category, user) => {
    const {data} = await $authHost.post('/addShop', {name, description, price, kit, icon, number, category, user})
    return data
}

export const shops = async () => {
    const {data} = await $authHost.get('/shops')
    return data
}

export const buyShop = async (id, card_id, kit) => {
    const {data} = await $authHost.post('/buyShop', {id, card_id, kit})
    return data
}

export const shopIcons = async () => {
    const {data} = await $authHost.get('/shopIcons')
    return data
}

export const addShopIcons = async (img, name) => {
    const {data} = await $authHost.post('/addShopIcons', {img, name})
    return data
}