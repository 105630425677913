import React from 'react'
import Header from '../components/Header'

const WikiGuides = () => {
  return (
    <div>
        <Header/>
    </div>
  )
}

export default WikiGuides