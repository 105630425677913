import React, { useLayoutEffect } from 'react'
import { IoArrowForward, IoList } from 'react-icons/io5'
import { useNavigate } from 'react-router-dom';
import { WIKI_ROUTE } from '../utils/consts';
import Header from '../components/Header';

import RulesPng from '../img/wiki_rules_1.png'
import WikiSideBar from '../components/WikiSideBar';

const WikiRulesCourt = () => {
    const navigate = useNavigate()
    const scrollToTargetElement = (targetElement) => {
        const element = document.getElementById(targetElement);
        if (element) {
            element.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
    }

    useLayoutEffect(() => {
        const targetElement = decodeURI(window.location.hash)
        const element = document.getElementById(targetElement.slice(targetElement.lastIndexOf('#') + 1));
        if (element) {
            element.scrollIntoView({ behavior: 'smooth', block: 'start' });
        } else {
            window.scrollTo(0, 0)
        }
    });

    return (
        <>
          <title>Вики ZeroShit ੶ Правила Суда</title>
          <Header/>
          <div className='wiki'>
              <WikiSideBar/>
              <div className='wiki_content'>
                <h1 id='правила-подачи-иска-в-суд'>Правила подачи иска в суд</h1>
                <p>Не соблюдение правил приводит к штрафу. Только после выплаты штрафа появиться возможность подавать иски снова.</p>
                <div className='wiki_content-warning'>
                  <h2 className='wiki_content-warning_indent'>Штраф можно будет оплатить во вкладке кошелек.</h2>
                </div>
                <h2 id='правила'>Правила</h2>
                <div className='wiki_content-rules'>
                    <div id='правила-подачи-иска'>
                        <h3>1. Правила подачи иска</h3>
                        <p>1.1 В поле краткая информация вы должны написать кратно, что у вас произошло.</p>
                        <p>1.2 В поле доказательства должна быть ссылка на скриншот преступления, ее можно сделать в дискорде отправя картинку и скопируя ее ссылку.</p>
                        <img src={RulesPng} alt=''/>
                        <p className='wiki_content-indent'>1.3 В иске не должно быть запрещенных слов плотформы Twitch.</p>
                    </div>
                    <div id='правила-суда' className='wiki_content-indent'>
                        <h3>2. Правила суда</h3>
                        <p>Вы должны присутствовать на суде.</p>
                        <div className='wiki_content-note wiki_content-indent'>
                            <h2>Примечание!</h2>
                            <p>Если вы не сможете прийти на суд, вы должны оповестить в этом судью. В этом случии штраф не будет выдан.</p>
                        </div>
                    </div>
                </div>
                <div className='wiki_content-next' onClick={() => navigate(WIKI_ROUTE + "/rules/mods")}>
                  <div>
                    <p>Далее</p>
                    <h3>Разрешенные модификации</h3>
                  </div>
                  <IoArrowForward className='wiki_content-next_arrow'/>
                </div>
              </div>
              <div className='wiki_on_page'>
                <div className='wiki_on_page-sticky'>
                  <h3>
                    <IoList className='wiki_on_page-sticky_list'/>
                    На этой странице
                  </h3>
                  <div className='wiki_on_page-sticky_navigation'>
                    <div>
                      <span onClick={() => scrollToTargetElement('правила')}>Правила</span>
                      <p onClick={() => scrollToTargetElement('правила-подачи-иска')}><span>Правила подачи иска</span></p>
                      <p onClick={() => scrollToTargetElement('правила-суда')}><span>Правила суда</span></p>
                    </div>
                  </div>
                </div>
              </div>
          </div>
        </>
      )
}

export default WikiRulesCourt