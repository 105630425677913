import { $authHost, $host } from "./index";
import jwt_decode from "jwt-decode";

export const authorization = async (code) => {
    try {
        const {data} = await $host.get('/api/users/authorization?code=' + code)
        console.log(data)
        if (data === null || data === undefined) {
            return null
        } else if (data.message === "expectation") {
            return {"message": "Ожидайте ответа Администрации!"}
        } else {
            localStorage.setItem('token', data.token)
            return jwt_decode(data.token)
        }
    } catch (error) {
        if (error.response) {
            console.error(error.response.status + " " + error.response.data.detail);
            if (error.response.status === 401 || error.response.status === 500) {
                return { "error": error.response.data.detail };
            }
        }
        console.error(error);
        return { "error": "Ошибка обратитесь к администрации" };
    }
}

export const authentication = async () => {
    try {
        const {data} = await $authHost.get('/api/users/authentication')
        if (data == null) {
            return data
        }
        localStorage.setItem('token', data.token)
        return jwt_decode(data.token)
    } catch (error){
        if (error.response) {
            console.error(error.response.status + " " + error.response.data.detail);
        }
        console.error(error);
        return { "error": "Ошибка обратитесь к администрации" };
    }
}

export const check = async () => {
    const {data} = await $authHost.get('/check')
    if (data == null) {
        return data
    }
    localStorage.setItem('token', data.token)
    return jwt_decode(data.token)
}

export const users = async (offset, limit) => {
    const { data } = await $host.get(`/api/users/all?offset=${offset}&limit=${limit}`);
    return data;
}

export const search_users = async (q) => {
    const { data } = await $host.get(`/api/users?q=${q}`);
    return data;
}

export const checkUser = async (id) => {
    const {data} = await $authHost.get('/users/' + id)
    return data
}

export const updateUser = async (nick, role) => {
    const {data} = await $authHost.post('/updateUser', {nick, role})
    return data
}

export const updateUserSettings = async (nick, description, avatar, avatar_mini, family) => {
    const {data} = await $authHost.post('/updateUserSettings', {nick, description, avatar, avatar_mini, family})
    return data
}

export const adminState = async () => {
    const {data} = await $authHost.get('/adminState')
    return data
}

export const addAdminState = async (wallet_build, wallet_build_msg, court_build, court_build_msg, shop_build, shop_build_msg) => {
    const {data} = await $authHost.post('/addAdminState', {wallet_build, wallet_build_msg, court_build, court_build_msg, shop_build, shop_build_msg})
    return data
}

export const search_promocode = async (promocode) => {
    try {
        const { data } = await $host.get(`/api/promocodes/check?promocode=${promocode}`)
        return data
    } catch (error) {
        if (error.response.status === 404) {
            return { "error": error.response.data.detail }
        } else {
            return null
        }
    }
    
}

export const get_payment_url = async (price, minecraft_nick, promocode) => {
    try {
        const { data } = await $authHost.get(`/api/payment/url?price=${price}&minecraft_nick=${minecraft_nick}&promocode=${promocode}`)
        return data
    } catch (error) {
        if (error.response.status === 404) {
            return { "error": error.response.data.detail }
        } else {
            return null
        }
    }
    
}
