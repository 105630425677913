import React, { useRef, useState, useEffect } from 'react';
import { MdKeyboardArrowDown, MdKeyboardArrowUp } from 'react-icons/md';
import { search_users } from '../http/userAPI';

const Dropdown = ({ lockOption, onSelect, defaultOption }) => {
  const [selectedOption, setSelectedOption] = useState(null);
  const selectedRef = useRef(null);
  const optionsRef = useRef(null);
  const [isOpen, setIsOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState('')
  const [searchResults, setSearchResults] = useState(undefined)

  useEffect(() => {
    const fetchSearchResults = async () => {
      try {
        setSearchResults("search")
        const response = await search_users(searchQuery)
        setSearchResults(response)
      } catch (error) {
        console.error(error);
      }
    }

    if (searchQuery.length >= 2) {
      fetchSearchResults()
    } else {
      setSearchResults(undefined)
    }
  }, [searchQuery])

  const handleOptionClick = (option) => {
    setSelectedOption(option)
    setIsOpen(false)
    console.log(option)
    if (option !== null) onSelect(option)
  }

  const toggleDropdown = () => {
    setIsOpen(!isOpen)
  }

  return (
    <div className="dropdown-container" onClick={() => setSearchQuery(lockOption !== null ? lockOption : "")}>
      {isOpen && (
        <>
        {lockOption !== null && (
          <>
            <input
            autoFocus
            value={lockOption}
            />
            <label>Ваш ник</label>
          </>
        )}
        {lockOption === null && (
          <>
            <input
            autoFocus
            onChange={(e) => setSearchQuery(e.target.value)}
            value={searchQuery}
            />
            <label>Укажите ник игрока</label>
          </>
        )}
          
        </>
      )}
      {isOpen === false && (
        <div className={`dropdown-selected ${isOpen ? 'open' : ''}`} onClick={toggleDropdown} ref={selectedRef}>
          {(selectedOption !== null ? (
            <>
              <img src={selectedOption.avatar} alt=''/><div><h4>{selectedOption.minecraft_nick}</h4>{selectedOption.title}</div> {isOpen ? <MdKeyboardArrowUp className='dropdown-selected-icon'/> : <MdKeyboardArrowDown className='dropdown-selected-icon'/>}
            </>
          ) : (
            <>{defaultOption} {isOpen ? <MdKeyboardArrowUp className='dropdown-selected-icon'/> : <MdKeyboardArrowDown className='dropdown-selected-icon'/>}</>
          ))}
        </div>
      )}
      
      {isOpen && (
        <ul className={`dropdown-options ${isOpen ? 'open' : ''}`} ref={optionsRef}>
          {searchResults === undefined && (
            <div className="dropdown-option no-select" onClick={() => handleOptionClick(null)}>
              {defaultOption}
            </div>
          )}
          {searchResults === "search" && (
            <div className="dropdown-option no-select" onClick={() => handleOptionClick(null)}>
              Поиск
            </div>
          )}
          {searchResults === null && (
            <div className="dropdown-option no-select" onClick={() => handleOptionClick(null)}>
              Ничего не найдено
            </div>
          )}
          {searchResults !== null && searchResults !== "search" && searchResults !== undefined && searchResults.map((result) => (
            <div className="dropdown-option" key={result.card_id} onClick={() => handleOptionClick(result)}>
              <img src={result.avatar} alt=''/>
              <div>
                <h4>{result.minecraft_nick}</h4>
                {result.title}
              </div>
            </div>
          ))}
        </ul>
      )}
    </div>
  )
}

export default Dropdown;