export const ADMIN_ROUTE = '/admin'
export const MAIN_ROUTE = '/'
export const GUEST_ROUTE = '/guest'
export const PROFILE_ROUTE = '/profile'
export const COURT_ROUTE = '/court'
export const WALLET_ROUTE = '/wallet'
export const USERS_ROUTE = '/users'
export const SHOP_ROUTE = '/shop'
export const SETTINGS_ROUTE = '/settings'
export const DONATE_ROUTE = '/donate'
export const DOGOVOR_ROUTE = '/dogovor'
export const CONTACTS_ROUTE = '/contacts'
export const MAP_ROUTE = '/map'
export const AUTH_MAP_ROUTE = '/auth/map'
export const STATISTIC_ROUTE = '/statistic'
export const AUTHORIZATION_ROUTE = '/authorization'
export const WIKI_ROUTE = '/wiki'
export const OAUTH_ROUTE = '/oauth'
export const FRESH_ROUTE = '/fresh'
