import React from 'react'
import { useNavigate } from 'react-router-dom';
import { MAIN_ROUTE } from '../utils/consts';

const Oauth = () => {
  const navigate = useNavigate()
  return (
    <div className='authorization'>
      <title>ZeroShit ੶ Авторизация в лаунчере</title>
      <h1>Авторизация в лаунчере</h1>
      {!localStorage.getItem("token") && (
        <div className='authorization-error'>
          <h3>Ошибка</h3>
          <h2>Попробуйте авторизоваться заного</h2>
          <div onClick={() => window.location.replace("https://discord.com/oauth2/authorize?client_id=1206144488750190653&response_type=code&redirect_uri=https%3A%2F%2Fzerosquad.fun%2Fauthorization&scope=identify+guilds+guilds.join")} className='header_login'>Авторизоваться</div>
        </div>
      )}
      {localStorage.getItem("token") && (
        <div className='authorization-error'>
          <h3>Успешно</h3>
          <p>Не кому не сообщайте этот токен!</p>
          <div className='button' onClick={() => {navigator.clipboard.writeText(localStorage.getItem('token')); alert("Токен скопирован"); navigate(MAIN_ROUTE)}}>Скопировать токен</div>
        </div>
      )}
    </div>
  )
}

export default Oauth