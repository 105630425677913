import React, { useContext, useEffect } from 'react'
import { Navigate } from 'react-router-dom'
import { useState } from 'react'
import { Routes, Route } from 'react-router-dom'
import { Context } from '../index.js'
import { noAuthRoutes, publicRoutes } from '../routes'
import { MAIN_ROUTE } from '../utils/consts.js'
import { authentication } from '../http/userAPI'


const AppRouter = () => {
  const {user} = useContext(Context)
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    authentication().then(data => {
      if (data !== null) {
        if (data.hasOwnProperty("data")) {
          user.setUser(data.data)
          user.setIsAuth(data.data.authorization)
        }
      }
    }).finally(() => setLoading(false))
    
  // eslint-disable-next-line
  }, [])

  if (loading) {
    return (
      <div className='load'>
        <div className="dot-spinner">
          <div className="dot-spinner__dot"></div>
          <div className="dot-spinner__dot"></div>
          <div className="dot-spinner__dot"></div>
          <div className="dot-spinner__dot"></div>
          <div className="dot-spinner__dot"></div>
          <div className="dot-spinner__dot"></div>
          <div className="dot-spinner__dot"></div>
          <div className="dot-spinner__dot"></div>
        </div>
      </div>
    )
  }


  return (
    <Routes>
      {user.isAuth === "false" && noAuthRoutes.map(({path, Component}) =>
        <Route key={path} path={path} element={Component} exact/>
      )}
      {publicRoutes.map(({path, Component}) =>
        <Route key={path} path={path} element={Component} exact/>
      )}
      <Route path="*" element={<Navigate to={MAIN_ROUTE} />} />
    </Routes>
  )
}

export default AppRouter