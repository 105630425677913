import React, { useLayoutEffect } from 'react'
import { IoArrowForward, IoList } from 'react-icons/io5'
import { useNavigate } from 'react-router-dom';
import { WIKI_ROUTE } from '../utils/consts';
import Header from '../components/Header';

import WikiSideBar from '../components/WikiSideBar';
import { BiLinkExternal } from 'react-icons/bi';

const WikiMechanics = () => {
    const navigate = useNavigate()
    const scrollToTargetElement = (targetElement) => {
        const element = document.getElementById(targetElement);
        if (element) {
            element.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
    }

    useLayoutEffect(() => {
        const targetElement = decodeURI(window.location.hash)
        const element = document.getElementById(targetElement.slice(targetElement.lastIndexOf('#') + 1));
        if (element) {
            element.scrollIntoView({ behavior: 'smooth', block: 'start' });
        } else {
            window.scrollTo(0, 0)
        }
    });

    return (
        <>
          <title>Вики ZeroShit ੶ Моды и Ресурспаки</title>
          <Header/>
          <div className='wiki'>
              <WikiSideBar/>
              <div className='wiki_content'>
                <h1 id='механики'>Механики сервера</h1>
                <p>Тут вы найдете основные механици сервера.</p>
                <h2 id='механики-чат'>Чат</h2>
                <div id='7tv'>
                    <h3>Новые смайлики в чате</h3>
                    <div className='wiki_content-link' onClick={() => navigate(WIKI_ROUTE + "/guide/plasmovoice")}>
                        <BiLinkExternal className='wiki_content-link_icon'/>
                        <span>Гайд по использованию</span>
                    </div>
                    <p>Обязательный мод обеспечивает комфортную игру с голосовым чатом, позволяющим общаться с другими игроками, устраивать жесткие пати с соундпадом.<br/><br/>Этот мод значительно обогащает игровой опыт, способствуя более эффективной координации и веселому взаимодействию с другими игроками.</p>
                    <div className='wiki_content-note'>
                        <h2>Совет!</h2>
                        <p>Если у вас нет микрофона или вы не хотите разговаривать, используйте мод чтобы слышать других игроков.</p>
                    </div>
                </div>
                <div id='emotecraft'>
                    <h3>EmoteCraft - мод на анимации (эмоции)</h3>
                    <div className='wiki_content-link' onClick={() => navigate(WIKI_ROUTE + "/guide/emotecraft")}>
                        <BiLinkExternal className='wiki_content-link_icon'/>
                        <span>Гайд по установке</span>
                    </div>
                    <p>Обязательный мод, обеспечивающий комфортную игру, придает новые возможности для выражения эмоций, танцев и более реалистичного ролевого взаимодействия.<br/><img src='https://i.imgur.com/tWD40Fg.png' alt=''/>Теперь вы сможете полностью погрузиться в игровой мир, общаясь с другими игроками через голосовой чат и воплощая свои эмоции во время игры.</p>
                </div>
                <h2 id='разрешенные-моды'>Разрешенные моды</h2>
                <h3>Все модификации (моды), которые не попадают под определение "чит" и не предоставляют преимущества над другими игроками.</h3>
                <div>
                    <h3>Исключения</h3>
                    <h4 id='разрешенные-моды-миникарта'>1. Мод на миникарту
                        <p>Но на миникарте должны быть отключены мобы и игроки.</p>
                    </h4>
                </div>
                <h2>Ресурспаки</h2>
                <div>
                    <h3>Серверный ресурспак</h3>
                    <div className='wiki_content-link' onClick=''>
                        <BiLinkExternal className='wiki_content-link_icon'/>
                        <span>Скачать с Google Drive</span>
                    </div>
                    <p>Благодаря ресурспаку вы сможете видеть в табе вместо символов картинки.</p>
                    
                </div>
                <div>
                    <h3>Роле плей ресурспак</h3>
                    <div className='wiki_content-link' onClick=''>
                        <BiLinkExternal className='wiki_content-link_icon'/>
                        <span>Скачать с Google Drive</span>
                    </div>
                    <p>Благодаря ресурспаку вы сможете видеть в табе вместо символов картинки.</p>
                    
                </div>
                <div className='wiki_content-next' onClick={() => navigate(WIKI_ROUTE + "/rules/mods")}>
                  <div>
                    <p>Далее</p>
                    <h3>Гайды</h3>
                  </div>
                  <IoArrowForward className='wiki_content-next_arrow'/>
                </div>
              </div>
              <div className='wiki_on_page'>
                <div className='wiki_on_page-sticky'>
                  <h3>
                    <IoList className='wiki_on_page-sticky_list'/>
                    На этой странице
                  </h3>
                  <div className='wiki_on_page-sticky_navigation'>
                    <div>
                      <span onClick={() => scrollToTargetElement('обязательные-моды')}>Обязательные моды</span>
                      <p onClick={() => scrollToTargetElement('plasmovoice')}>PlasmoVoice</p>
                      <p onClick={() => scrollToTargetElement('emotecraft')}>EmoteCraft</p>
                      <span onClick={() => scrollToTargetElement('разрешенные-моды')}>Разрешенные моды</span>
                      <p onClick={() => scrollToTargetElement('разрешенные-моды-миникартаp')}>Миникарта</p>
                    </div>
                  </div>
                </div>
              </div>
          </div>
        </>
    )
}

export default WikiMechanics